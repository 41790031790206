import React from 'react'
import OnePage from './onePage'
import SuccessCase from "../successCase";
import LastBlock from "../lastBlock";
import styles from './index.module.less'

const projectCase:React.FC<any>=()=> {
  return (
   <>
   <div className={styles.caseContainer}>
    {/* 首页 */}
     <OnePage/>
    {/* 成功案例 */}
    <SuccessCase />
    {/* 尾部 */}
    <LastBlock />
   </div>
   </>
  )
}
export default projectCase