
import React from "react";
import TitlePage from "./item/TitlePage";
import styles from './index.module.less'
import SecondBlock from "./item/SecondBlock";
import ThirdBlock from "./item/ThirdBlock";
import FourthBlock from "./item/FourthBlock";
import FifthBlock from "./item/FifthBlock";
import SixthBlock from "./item/SixthBlock";
import LastBlock from "../../lastBlock";

const WisdomHose: React.FC<any> = () => {
    return <>
        <div className={styles.wisdomHose}>
            <TitlePage />
            <SecondBlock />
            {/* 模型等级 */}
            <ThirdBlock />
            {/* 产品功能 */}
            <FourthBlock />
            {/* 客户价值 */}
            <FifthBlock />
            {/* 实践案例 */}
            <SixthBlock />
            {/* foot */}
            <LastBlock />
        </div>
    </>
}

export default WisdomHose