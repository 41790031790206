import React from 'react'
import { titleIcon } from '@/assets/image'
import { MenuButton } from './menuButton'
import { useNavigate } from "react-router-dom";
import styles from './index.module.less'
import { useStore } from '@/hooks';


export const Title = () => {
  const navigate = useNavigate();
  const [state, setState] = React.useState(false)
  const [Fgmenustate, setFgMenustate] = React.useState(null)
  const [FgClickmenustate, setClickFgMenustate] = useStore("FgClickmenustate,",null)
  const [menuState, setMenuState] = useStore('MenuState', )

  const clickItem = (fg: any) => {
    setFgMenustate(fg)
  }
  const clickItemLeave = (fg: any) => {
    setFgMenustate(fg)
  }

  // 监听滚动事件
  window.addEventListener('scroll', function () {
    // 获取滚动的高度
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    // 执行其他操作，例如更新UI、触发动画等
    // console.log('滚动高度：', scrollTop);
    if (scrollTop >= 200) {
      setState(true)
    } else {
      setState(false)
    }
  });

  // 关于飞戈
  const AboutFg = (fg:any) => {
    setMenuState(null)
    navigate('/home/aboutFg')
    setClickFgMenustate(fg)
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  // 联系我们
const connectionFg=(fg:any)=>{
  setMenuState(null)
  navigate('/home/connection')
  setClickFgMenustate(fg)
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
}


  return (
    <>
      <div className={styles.titleBg} style={{ backgroundColor: state ? 'rgba(0,0,0,1)' : '' }}>

        <div className={styles.container}>
          {/* <div className={styles.titleIcon} > */}
          <img className={styles.titleIcon} src={titleIcon} style={{ width: 145 }} />
          {/* </div> */}
          <div> <MenuButton /></div>
          <div className={styles.message}>

            <div onMouseEnter={() => clickItem("关于飞戈")} onMouseLeave={() => clickItemLeave(null)} onClick={() => AboutFg("关于飞戈")} className={styles.FGMessage} style={{ marginRight: '-10px', backgroundImage: (Fgmenustate === "关于飞戈" ||FgClickmenustate=== "关于飞戈" )? 'linear-gradient(to top, rgba(26, 91, 255, .5), rgba(26, 91, 255, 0)' : "", zIndex: 3 }}>
              <span>关于飞戈</span>
              <div style={{ display:( Fgmenustate === "关于飞戈" ||FgClickmenustate=== "关于飞戈") ? 'block' : 'none' }} className={styles.underline}></div>
            </div>

            <div className={styles.line} />

            <div onMouseEnter={() => clickItem("联系我们")} onMouseLeave={() => clickItemLeave(null)} onClick={() => connectionFg("联系我们")} className={styles.FGMessage} style={{ marginLeft: '-38px', backgroundImage: (Fgmenustate === "联系我们"||FgClickmenustate==="联系我们") ? 'linear-gradient(to top, rgba(26, 91, 255, .5), rgba(26, 91, 255, 0)' : "", zIndex: 3 }}>
              <span>联系我们</span>
              <div style={{ display: (Fgmenustate === "联系我们"||FgClickmenustate==="联系我们")? 'block' : 'none' }} className={styles.underline}></div>
              <div></div>
            </div>
          </div>
        </div>

      </div >
    </>
  )
}
