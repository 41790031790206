import React, { useState, useRef } from 'react'
import { top, CustomerService, message, weChart, telephone, twoCode } from '@/assets/image'
import TelePhone from './telephone'
import styles from './index.module.less'
import Message from './message'

export const HoverMenu = () => {
  const [state, setState] = useState<any>(null)
  const setHover = (item: any) => {
    setState(item.key)
  }
  const pageTop = useRef<any>(null);

  const OnMouseMessage = (item: any) => {
    setState(item)
  }
  const onCancelHover = () => {

  }
  const handleClick = () => {
    // window.HTMLBodyElement.scrollTop = 0;
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
console.log(state);

  return (
    <>
      <div>
        
        <div className={styles.hoverTwoCode} style={{ display: state === "weChart" ? '' : 'none' }}>
          <img width={102} src={twoCode} />
        </div>

        <div className={styles.hoverTwoCode} style={{ display: state === "telephone" ? '' : 'none' }}>
          <TelePhone />
        </div>
        <div className={styles.hoverContainer}>
          <ul>
            <li><img ref={pageTop} onClick={() => handleClick()} width={45} src={top} /></li>
            <li><a href=""><img width={45} src={CustomerService} /></a></li>
            <li ><img width={45} src={message} onMouseEnter={() => OnMouseMessage("message")} /></li>
            <li onMouseLeave={() => { setState('') }} onMouseEnter={() => OnMouseMessage("weChart")}><img width={45} src={weChart} /></li>
            <li><img width={45} src={telephone} onMouseEnter={() => OnMouseMessage("telephone")} onMouseLeave={() => { setState('') }} /></li>
          </ul>
          <div className={styles.toolbarDiv} >
           {state==="message"&&
             <Message />
           }
          </div>

          {/* {
            arr?.map(item => {
              return <>
                <img width={45} src={item.src} onClick={() => setHover(item)} />
              </>
            })
          } */}
        </div>
      </div>
    </>
  )
}
const arr = [{
  key: 'code1',
  src: top
}, {
  key: 'code2',
  src: CustomerService
}
  , {
  key: 'code3',
  src: message
}
  , {
  key: 'code4',
  src: weChart
}
  , {
  key: 'code5',
  src: telephone
}
]
