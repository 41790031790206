import React from 'react'
import AboutOnePage from "./aboutOnePage";
import FgIntroduce from "../fgIntroduce";
import DevelopTime from './developTime'
import Culture from './culture';
import styles from './index.module.less'
import Aptitude from './aptitude';
import KnowMore from './knowMore';
import LastBlock from "../lastBlock";

const AboutFg: React.FC<any> = () => {
  return (
    <>
      <div className={styles.container}>
        {/* 关于飞戈首页 */}
        <AboutOnePage />
        {/* 飞戈数字介绍 */}
        <FgIntroduce />
        {/* 发展历程 */}
        <DevelopTime />
        {/* 企业文化 */}
        <Culture />
        {/* 公司资质 */}
        <Aptitude />
        {/* 了解更多 */}
        <KnowMore />
        {/* 底图 */}
        <LastBlock />
      </div>
    </>
  )
}
export default AboutFg;