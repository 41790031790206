import React from 'react'
import {connectionBg} from '@/assets/image'
import styles from './index.module.less'

const ConOnePage:React.FC<any>=()=> {
  return (
   <>
    <div className={styles.onePageContainer} style={{
        background: `url(${connectionBg})`,
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat'
      }}>
        {/* <HoverMenu /> */}
        <div className={styles.titleBox}>
          <div className={styles.bigTitle} >联系我们</div>
          <div className={styles.context} >
            <p>飞戈数字孪生，助力政企数字化转型</p>
          </div>
        </div>
      </div >
   </>
  )
}
export default ConOnePage