// 菜单
const menu = [
    {
        label: '新能源',
        key: 'HomePage',
    },
    {
        label: '乡村振兴',
        key: 'Service',
        children: [
            {
                label: '政务党建',
                key: 'Village'
            },
            {
                label: '智慧牧场',
                key: 'Village'
            },
            {
                label: '数字乡村',
                key: 'Village'
            },
            {
                label: '智慧景区',
                key: 'Village'
            },
            {
                label: '智慧农业',
                key: 'Village'
            }
        ]
    },
    {
        label: '智慧系列',
        key: 'WorkOut',
    },
    {
        label: '智慧水利',
        key: 'Village'
    },
    {
        label: '文旅文博',
        key: 'Village'
    },
    {
        label: '展览展示',
        key: 'Village'
    },
    {
        label: '楼盘CIM系列',
        key: 'Village'
    },
]

export default menu