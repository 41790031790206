import React from 'react'
import ServiceOnePage from './serviceOnePage'
import styles from './index.module.less'
import AesModel from './aesModel'
import Construct from './construct'
import Feature from './feature'
import LastBlock from '../lastBlock'

const ProductService: React.FC<any> = () => {
  return (
    <>
      <div className={styles.container}>
        {/* 数字孪生 */}
        <ServiceOnePage />
        {/* AES场景建模 */}
        <AesModel />
        {/* 如何搭建AES */}
        <Construct />
        {/* 功能 */}
        <Feature />
        {/* 底图 */}
        <LastBlock />
      </div>
    </>
  )
}
export default ProductService;