import { Carousel } from "antd";
import React from "react";
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import styles  from './index.module.less'

const SelfCarousel: React.FC<any> = (props: any) => {
    return <>
        <div className={styles.carouselBox}>
            <Carousel
                arrows
                nextArrow={<RightOutlined className="lll" />}
                prevArrow={< LeftOutlined className="rrr" />}
                {...props}
            >
                {props.children}
            </Carousel>
        </div>
    </>
}

export default SelfCarousel