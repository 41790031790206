import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import styles from '../index.module.less'
import classNames from 'classnames'
import { useStore } from '@/hooks';

export const MenuButton = () => {
  const [state, setState] = useState(null)
  const [menuState, setMenuState] = useStore('MenuState', null)
  const [FgClickmenustate, setClickFgMenustate] = useStore("FgClickmenustate,",)
  const navigate = useNavigate();
  const location = useLocation();
// 移入
  const clickItem = (item: any) => {
    state === item?.label ? setState(null) : setState(item?.label)
  }
  // 移出
  const clickItemLeave = (item: any) => {
    item?.children ? setState(item?.label) : setState(null)
  }
// 跳转页面
  const onChangPage = (param: any) => {
    navigate(param.path)
    setMenuState(param)
    setClickFgMenustate(null)
    /* if (item.label === '首页') {
      navigate('/home')
    } else if (item.label === '产品服务') {
      navigate('/home/productService')
    } else if (item.label === '解决方案') {
      navigate('/home/solution')
    } */
    /* else {
      navigate('/home')
    } */
  }

  //  *======= 子页面点击事件 =======*
  const onItemPage = (e: any, param: any) => {
    e.stopPropagation()
    setMenuState(param)
    navigate(param.path)

  }

  useEffect(() => {
    // console.log('location', location);    // 获取当前路由
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, [menuState])

  return (
    <>
      <div className={styles.menuButton}>
        {
          menu?.map((item) => {
            return <>
              <div
                key={item.key}
                className={classNames(styles.position,
                  menuState?.label === item?.label && styles.active
                )}
                onMouseEnter={() => clickItem(item)}
                onMouseLeave={() => clickItemLeave(item)}
                onClick={() => onChangPage(item)}
                style={{ backgroundImage: state === item?.label ? 'linear-gradient(to top, rgba(26, 91, 255, .5), rgba(26, 91, 255, 0)' : "" }}
              >
                <span> {item.label}</span>
                {(state === item?.label || menuState?.label === item?.label)&&!item.children &&
                  <>
                    <div style={{
                      height: 3, width: 37,
                      backgroundColor: "rgba(0, 140, 242, 1)",
                      position: 'relative',
                      top: '-6px',
                      margin: '0 auto',
                      borderRadius: '1.5px'
                    }}
                    />
                  </>
                }
                {
                  item?.children?.map(item => {
                    return <>
                      <div key={item.key} className={classNames(styles.select,
                        menuState?.label === item?.label && styles.selectActive)
                      }
                        onClick={(e: any) => onItemPage(e, item)}
                      >
                        <span style={{ position: 'relative', top: '-10px', padding: '5px' }} >{item.label}</span>
                      </div>
                    </>
                  })
                }
              </div >
            </>
          })
        }
      </div >
    </>
  )
}

const menu = [
  {
    label: '首页',
    key: 'HomePage',
    path: '/home',
  },
  {
    label: '数字孪生',
    key: 'Service',
    path: '/home/productService',
  },{
    label: '元宇宙',
    key: 'Metaverse',
    path: '/home/metaverse',
  },
  {
    label: '解决方案',
    key: 'Solution',
    // path: '/home/solution',
    children: [
      {
        label: '智慧房产',
        key: 'WisdomHose',
        path: '/home/solution/wisdomHose',
      }, 
      {
        label: '乡村振兴',
        key: 'FigureVillage',
        path: '/home/solution/figurevillage',
      },
      {
        label: '智慧矿山',
        key: 'WisdomMine',
        path: '/home/solution/smartmine',
      },
      {
        label: '智慧园区',
        key: 'WisdomPark',
        path: '/home/solution/wisdompark',
      },
      {
        label: '智慧校园',
        key: 'WisdomCampus',
        path: '/home/solution/wisdomcampus',
      },
      // {
      //   label: '智慧城市',
      //   key: 'WisdomCity'
      // }, {
      //   label: '智慧能源',
      //   key: 'WisdomEnergy'
      // }, 
      // {
      //   label: '智慧水电站',
      //   key: 'WisdomWater',
      //   path: '/home/solution/wisdomwater',
      // },
    ]
  },
  {
    label: '项目案例',
    key: 'Case',
    path: '/home/projectcase'
  },
  {
    label: '生态合作',
    key: 'Cooperation',
    path: '/home/cooperation',
  },
]