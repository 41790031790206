import React from 'react'
import { show1, show2, show3, show4, onemine, twomine, threemine, fourmine, fivemine, sixmine, sevenmine,village1,village2,village3,village4,village5,village6,
  campus1,campus2,campus3,campus4,campus5,
  wisdomwater2,wisdomwater3,wisdomwater4,wisdomwater5,wisdomwater6,
park2,park3,park4,park5,} from '@/assets/image'
import styles from './index.module.less'

const SystemDisplay: React.FC<any> = (props: any) => {
  const { ChildrenState } = props
  return (
    <>
      <div className={styles.sysContainer}>
        <p className={styles.sysTitle}>系统展示</p>
        <p className={styles.sysLittleTitle}>资源总览</p>
        {
          mineArr?.map(item => {
            if (item.name === ChildrenState) {
              return <>
                <p className={styles.sysContent}> {item.content}</p>
                <img width={1204} src={item.src} alt="" />
                <div className={styles.sysCard}>
                  {
                   item?.arr?.map((item1, index) => {
                      return <>
                        {
                          (index + 1) % 2 !== 0 && <>
                            <div className={styles.sysMain} key={item1.key}>
                              <div className={styles.sysImg}>  <img src={item1.src} /></div>
                              <div className={styles.sysBox}>
                                <div className={styles.boxTitle}>{item1.name}</div>
                                <div className={styles.sysLine}></div>
                                <div className={styles.boxContent}>{item1.content}</div>
                              </div>
                            </div></>
                        }
                        {
                          (index + 1) % 2 === 0 && <>
                            <div className={styles.sysMain} key={item1.key}>
                              <div className={styles.sysBox} style={{ width: '576px', marginRight: '58px' }}>
                                <div className={styles.boxTitle} style={{ marginLeft: '-44px' }}>{item1.name}</div>
                                <div className={styles.sysLine} style={{ marginLeft: '-44px' }}></div>
                                <div className={styles.boxContent} style={{ marginLeft: '-44px' }}>{item1.content}</div>
                              </div>
                              <div className={styles.sysImg}> <img src={item1.src} /></div>
                            </div></>
                        }

                      </>
                    })
                  }
                </div>
              </>
            }
            // return <>
            //   <p className={styles.sysContent}> 资源总览展示了矿山的整体情况，包括矿山产业相关情况、设备维护情况、矿井类型分布数量等。</p>
            //   <img width={1204} src={show1} alt="" />
            //   <div className={styles.sysCard}>
            //     {
            //       sysArr?.map((item, index) => {
            //         return <>
            //           {
            //             (index + 1) % 2 !== 0 && <>
            //               <div className={styles.sysMain} key={item.key}>
            //                 <div className={styles.sysImg}>  <img src={item.src} /></div>
            //                 <div className={styles.sysBox}>
            //                   <div className={styles.boxTitle}>{item.name}</div>
            //                   <div className={styles.sysLine}></div>
            //                   <div className={styles.boxContent}>{item.content}</div>
            //                 </div>
            //               </div></>
            //           }
            //           {
            //             (index + 1) % 2 === 0 && <>
            //               <div className={styles.sysMain} key={item.key}>
            //                 <div className={styles.sysBox} style={{ width: '576px', marginRight: '58px' }}>
            //                   <div className={styles.boxTitle} style={{ marginLeft: '-44px' }}>{item.name}</div>
            //                   <div className={styles.sysLine} style={{ marginLeft: '-44px' }}></div>
            //                   <div className={styles.boxContent} style={{ marginLeft: '-44px' }}>{item.content}</div>
            //                 </div>
            //                 <div className={styles.sysImg}> <img src={item.src} /></div>
            //               </div></>
            //           }

            //         </>
            //       })
            //     }
            //   </div>
            // </>
          })
        }
      </div>
    </>
  )
}
export default SystemDisplay
const sysArr = [
  {
    key: 'sys1',
    name: '作业情况监测',
    content: '通过数字孪生技术对矿山进行数字化还原，并对矿洞位置进行标记，实现了对各个矿洞 作业情况的实时监测。',
    src: show2
  },
  {
    key: 'sys2',
    name: '车辆出入情况监测',
    content: '利用传感器设备，对各个卡口的车辆进出情况进行监测，实现了车辆出入的统一监测。',
    src: show3
  },
  {
    key: 'sys3',
    name: '实时视频监控',
    content: '通过点击监控标记，能够将对应摄像头的监控视频进行实时展示，并且在3D模型中展 现了不同监控点具体的位置，能够帮助管理人员更好地掌握各个监控区域的真实状况。',
    src: show4
  },
]
const mineArr = [
  {
    key:'name1',
    name: '智慧矿山',
    content: "项目总览展示了矿山的整体情况，包括矿山产业相关情况、地理位置、交通优势、供应市场、工程布置等。",
    src: twomine,
    arr: [
      {
        key: 'sys1',
        name: '地理位置',
        content: '通过数字孪生技术对矿山进行数字化还原，并对矿洞位置进行标记，实现了对各个矿洞 作业情况的实时监测。',
        src: fourmine,
      },
      {
        key: 'sys2',
        name: '交通优势',
        content: '此矿山的地理位置，在公路，水路，铁路上的交通优势的介绍。',
        src: fivemine,
      },
      {
        key: 'sys3',
        name: '矿山开采',
        content: '对矿山的各种设备、系统进行数字建模，以实现模拟仿真和监测管理等功能，包括了地质岩性，勘测成果，储量管理，排土场管理，车辆管理，开采模拟。',
        src: sixmine,
      },
      {
        key: 'sys4',
        name: '砂石工厂',
        content: '将矿山设备、流程、人员等实时监测和管理，形成一个数字化的矿山模型。通过数字孪生工厂，可以实现矿山生产的高效、智能、安全和环保，降低生产成本，提高生产效率和品质。数字孪生工厂还可以通过模拟不同的生产情况和决策，优化矿山生产过程，提前预判和避免潜在的问题和事故，为矿山生产决策提供数据支持和参考。',
        src: sevenmine
      },
    ]
  },
  {
    key:'name2',
    name: '乡村振兴',
    content: "项目位置展示了乡村的整体情况，包括乡村产业相关情况、地理位置、城市区位、景区规划、区域交通等。",
    src: village1,
    arr: [
      // {
      //   key: 'sys1',
      //   name: '项目位置',
      //   content: "项目位置展示了乡村的整体情况，包括乡村产业相关情况、地理位置、城市区位、景区规划、区域交通等。",
      //   src: twomine,
      // },
      {
        key: 'sys1',
        name: '景区规划',
        content: '通过数字孪生技术，将景区的地形、景点、景观、设施等信息建模，形成数字孪生景区。',
        src: village2,
      },
      {
        key: 'sys2',
        name: '交通优势',
        content: '此矿山的地理位置，在公路，水路，铁路上的交通优势的介绍。',
        src: village3,
      },
      {
        key: 'sys3',
        name: '乡村振兴',
        content: '对乡村的各种系统进行数字建模，以实现模拟仿真和监测管理等功能，包括了基本情况（农村情况，土地情况）、规划展示、特色种植（水果）、收入情况（集体收入，人均收入）、产业情况（第一第二产业）、游客统计。',
        src: village4,
      },
      {
        key: 'sys4',
        name: '党建引领',
        content: '党建引领数字孪生技术在乡村振兴中的应用，可以在政策层面保证数字孪生技术与乡村振兴的目标相契合，推动数字孪生技术的应用方向与乡村振兴战略目标相一致，实现双方互相促进、共同发展，包括：党员服务（四必访，四必到）、民情日记、积分考核、党员服务。',
        src: village5
      },
      {
        key: 'sys5',
        name: '网格管理',
        content: '网格管理指的是将物联网、云计算、大数据等新一代信息技术应用到乡村网格化管理中，实现智能化的乡村网格化管理。其目的是为了提高乡村管理的效率，提升生产生活质量，促进乡村振兴，包括：人房网格、视频监管、网格信息、垃圾中转、文体设施。',
        src: village6
      },
    ]
  },
  {
    key:'name3',
    name: '智慧园区',
    content: "",
    src: park2,
    arr: [
      // {
      //   key: 'sys1',
      //   name: '项目位置',
      //   content: "项目位置展示了乡村的整体情况，包括乡村产业相关情况、地理位置、城市区位、景区规划、区域交通等。",
      //   src: twomine,
      // },
      {
        key: 'sys1',
        name: '资源管理',
        content: '通过整合园区内能耗数据，对园区供电、供水、空调和电梯等各个子系统的运行状态进行实时监控，支持对各个子系统的数据进行多维可视分析展示，帮助管理者实时了解园区资源状况，为资源合理调配、园区节能减排提供有力的支撑。',
        src: park3,
      },
      {
        key: 'sys2',
        name: '设备维护',
        content: '通过3D机器模型，对设备的外观、内部机械结构等进行多角度仿真显示，支持集成设备运行监测以及其他传感器实时上传的监测数据，对设备位置、类型、运行状态进行监控，对设备详细信息进行查询，从而辅助管理者及时发现设备安全隐患，提升设备运维效率。',
        src: park4,
      },
      {
        key: 'sys3',
        name: '智慧安防',
        content: '支持集成视频监控系统、电子巡更系统、卡口系统等园区安全防范管理系统数据，支持对园区重点建筑、人员、车辆、突发事件等要素进行实时监测，支持安防报警事件后能快速查看事件详细信息、定位，实时调取事件周边监控视频，辅助管理者有效提升园区安全管控效力。',
        src: park5,
      },
    ]
  },
  {
    key:'name4',
    name: '智慧校园',
    content: "学校概览功能，学校管理人员可以快速了解整个校园的情况，并能够针对特定区域或设备进行监控和管理。比如，可以实时查看教室的使用情况，了解每个教室的课堂情况、学生考勤情况等。同时，还可以通过概览系统对设备设施进行监控，及时发现并解决故障，提高校园设施的运行效率。",
    src:  campus1,
    arr: [
      // {
      //   key: 'sys1',
      //   name: '项目位置',
      //   content: "项目位置展示了乡村的整体情况，包括乡村产业相关情况、地理位置、城市区位、景区规划、区域交通等。",
      //   src: twomine,
      // },
      {
        key: 'sys1',
        name: '智慧教学',
        content: '数字孪生智慧校园系统可以帮助教师进行教学内容管理和课堂管理。教师可以通过系统预先准备教学材料、课件和多媒体资源，并在课堂上实时展示和使用。',
        src: campus2,
      },
      {
        key: 'sys2',
        name: '智慧教学',
        content: '同时，系统还能记录学生的课堂表现和互动情况，帮助教师了解学生的学习状况并进行及时调整和辅导。',
        src: campus3,
      },
      {
        key: 'sys3',
        name: '学生管理',
        content: '可以实现学生信息采集、学生档案管理、学生教学管理、学生行为管理和安全管理等多个方面的智慧化管理。',
        src: campus4,
      },
      {
        key: 'sys4',
        name: '智慧规划',
        content: '数字孪生智慧校园系统在智慧规划方面发挥了重要作用。它可以帮助学校进行综合规划，包括学校建设规划、教学资源规划、师资规划、学生招生规划等方面。',
        src: campus5,
      },
    ]
  },
  {
    key:'name4',
    name: '智慧水电站',
    content: "",
    src: wisdomwater2,
    arr: [
      // {
      //   key: 'sys1',
      //   name: '项目位置',
      //   content: "项目位置展示了乡村的整体情况，包括乡村产业相关情况、地理位置、城市区位、景区规划、区域交通等。",
      //   src: twomine,
      // },
      {
        key: 'sys1',
        name: '工程位置',
        content: '项目总览展示了水电站的整体情况，包括水电站产业相关情况、地理位置、交通优势、供应市场、工程布置等。',
        src: wisdomwater3,
      },
      {
        key: 'sys2',
        name: '工程概况',
        content: '自动化控制系统、智能巡检系统、在线监测系统、移动终端管理系统、数据分析系统等。其中，自动化控制系统能够实现水电站的自动化控制、遥控、遥测和遥信；智能巡检系统可以帮助工作人员实现对水电站设备的定期巡检和维修。',
        src: wisdomwater4,
      },
      {
        key: 'sys3',
        name: '数字建造',
        content: '数字建造是指利用数字化技术和工具，将传统的水电站建设过程转化为数字化的过程。数字建造通过建模、仿真、优化等技术手段，在水电站的设计、施工、运营等各个阶段实现数字化的管理和协作。',
        src: wisdomwater5,
      },
      {
        key: 'sys4',
        name: '工程管理',
        content: '工程管理是一种基于数字化技术和工具，对水电站工程进行全过程、全要素、全环节的数字化管理系统。它主要包括工程进度、预算、施工现场安全、质量、供应链、人员管理等多方面的管理模块。',
        src: wisdomwater6,
      },
    ]
  },
]