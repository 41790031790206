import React from 'react'
import styles from './index.module.less'

const ServiceOnePage: React.FC<any> = () => {
  return (
    <>
      <div className={styles.onePageContainer}>
      <div className={styles.titleBox}>
          <div className={styles.bigTitle}>数字孪生</div>
          <div className={styles.line}></div>
          <div className={styles.context}>
          数字孪生是充分利用物理模型、传感器更新、运行历史等数据，集成多学科、多物理量、多尺度、多概率的仿真过程在虚拟空间中完成映射，从而反映相对应的实体装备的全生命周期过程。“数字孪生”强调仿真、建模、分析和辅助决策，侧重的是物理世界对象在数据世界的重现、分析、决策与飞戈可视化数字孪生产品功能特性不谋而合。
          </div>
        </div>
      </div>
      <div className={styles.onePageBgMask}>
      </div>
    </>
  )
}
export default ServiceOnePage