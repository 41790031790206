import React from 'react'
import styles from './index.module.less'

const CooperationGoal: React.FC<any> = () => {
    return <>
        <div className={styles.coperationGoal}>
            <div className={styles.title}>合作目标</div>
            <div className={styles.body}>
                <div className={styles.leftBox}>
                    <div>
                        致力于建设虚拟现实产业集群生态，<br />
                        协助合作伙伴及政企客户实现高质量发展，
                    </div>
                    <div style={{ color: '#5F96FD ' }}>
                        现诚邀多种类型合作伙伴加入，<br />
                        助力企业数字化转型，赋能组织智能化升级。
                    </div>
                </div>
                <div className={styles.rightBox}></div>
            </div>
        </div>
    </>
}

export default CooperationGoal