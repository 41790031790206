import React, { useState } from 'react'
import { certificate1, certificate2, certificate3, certificate4, certificate5, certificate6, certificate7, certificate8, certificate9, certificate10, leftAboutArrow, RightAboutArrow,certificate11, } from '@/assets/image'
import styles from './index.module.less'

const Aptitude: React.FC<any> = () => {
  const [state, setState] = useState(0)
  // const arr = aptArr.concat(...aptA)
  const add = () => {
    console.log(aptArr);
    state >= aptArr.length / 2 ? setState(0) : setState(state + 1)
  }
  const reduce = () => {
    state <= 0 ? setState(aptArr.length / 2 - 1) : setState(state - 1)
  }
  return (
    <>
      <div className={styles.devContainer} >
        <div className={styles.aptHeader}>
          <div className={styles.devTitle}>公司资质</div>
          <div className={styles.devTitleContent}><p>飞戈数字荣获城市基础数据可视化平台、智慧乡村CCIM系统管理平台、云房VR线上带看系统等10项软件著</p><p>作权，并获得湖南省软件行业协会颁发的软件产品证书和软件企业证书。</p></div>
        </div>
        <div>
          <div className={styles.devContent}>
            <div className={styles.devBoxImgLeft}>  < img src={leftAboutArrow} onClick={() => reduce()} /></div>

            <div className={styles.aptBox}><img width={306} src={aptArr[state].src} /></div>
            <div className={styles.aptBox}><img width={306} src={aptArr[state + 1].src} /></div>
            <div className={styles.aptBox}><img width={306} src={aptArr[state + 2].src} /></div>
            <div className={styles.aptBox}><img width={306} src={aptArr[state + 3].src} /></div>

            <div className={styles.devBoxImgRight}> <img src={RightAboutArrow} alt="" onClick={() => add()} /></div>
          </div>
        </div>
      </div>
    </>
  )
}
const aptArr = [
{
  key: 'apt1',
  src: certificate1
}, {
  key: 'apt2',
  src: certificate2
}, {
  key: 'apt3',
  src: certificate3
}, {
  key: 'apt4',
  src: certificate4
}, {
  key: 'apt5',
  src: certificate5
},
{
  key: 'apt5',
  src: certificate6
},
{
  key: 'apt6',
  src: certificate7
},
{
  key: 'apt7',
  src: certificate8
},
{
  key: 'apt8',
  src: certificate9
},
{
  key: 'apt9',
  src: certificate10
},
{
  key: 'apt10',
  src: certificate11
},
{
  key: 'apt1',
  src: certificate1
}, {
  key: 'apt2',
  src: certificate2
}, {
  key: 'apt3',
  src: certificate3
}, {
  key: 'apt4',
  src: certificate4
}, {
  key: 'apt5',
  src: certificate5
},
{
  key: 'apt5',
  src: certificate6
},
{
  key: 'apt6',
  src: certificate7
},
{
  key: 'apt7',
  src: certificate8
},
{
  key: 'apt8',
  src: certificate9
},
{
  key: 'apt9',
  src: certificate10
},
{
  key: 'apt10',
  src: certificate11
},
]
export default Aptitude

