import React, { useState } from 'react';
import styles from './index.module.less'
import menu from './menuData'
import classNames from 'classnames'
import { useStore } from '@/hooks';

export const Menu = () => {
  const [state, setState] = useState(null)
  const [footMenuState, setFootMenuState] = useStore("footMenuState", null)

  const clickItem = (item: any) => {
    state === item?.label ? setState(state) : setState(item?.label)
  }

  const clickItemLeave = (item: any) => {
    item?.children ? setState(item?.label) : setState(null)
  }
  // 跳转页面
  const onChangPage = (param: any) => {
    setFootMenuState(param)
  }
    //  *======= 子页面点击事件 =======*
    const onItemPage = ( param: any) => {
      setFootMenuState(param)
    }
  return (
    <>
      <div className={styles.menuBg} key={Math.random()}>
        <div className={styles.menu}>
          {
            menu?.map((item, index) => {
              return <>
                <div className={classNames(styles.position,
                  footMenuState?.label === item?.label && styles.active)} key={index}
                  onMouseEnter={() => clickItem(item)} onMouseLeave={() => clickItemLeave(item)}
                  onClick={() => onChangPage(item)}
                  style={{ backgroundImage: state === item?.label ? 'linear-gradient(to top, rgba(26, 91, 255, .5), rgba(26, 91, 255, 0)' : "" }}>
                  <span> {item.label}</span>
                  {(state === item?.label || footMenuState?.label === item?.label) && !item.children &&
                    <>
                      <div style={{ height: 3, width: 72, backgroundColor: "rgba(0, 140, 242, 1)", position: 'relative', top: '-6px', margin: '0 auto', borderRadius: '3px' }} />
                    </>
                  }
                  {
                    item?.children?.map(item => {
                      return <>
                        <div key={item.key} className={classNames(styles.position,
                          footMenuState?.label === item?.label && styles.active) && styles.select} onClick={() =>onItemPage(item)}>
                          <span style={{ position: 'relative', top: '-10px', padding: '5px', cursor: 'pointer', }}>
                            {item.label}
                          </span>
                        </div>
                      </>
                    })
                  }
                </div >
              </>
            })
          }
        </div >
      </div>
    </>
  )
}
