import React from 'react'
import styles from './index.module.less'


// 生态环保

const Ecology: React.FC<any> = () => {
    return <>
        <div className={styles.ecologyBox}>
            {/* <video src="https://cloud-assets-brwq.oss-cn-heyuan.aliyuncs.com/wutu417/uploads/20230601/4aca6bd28aa5ad36ad6e247f37eb7453.mp4"
                autoPlay loop muted
                className={styles.video}
                /> */}
                <div className={styles.title}>生态合作</div>
        </div>
    </>
}

export default Ecology