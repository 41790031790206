import React from 'react'
import { introduce,threemine ,village,park1, wisdomwater1,campusone} from '@/assets/image'
import styles from './index.module.less'

const Introduction: React.FC<any> = (props: any) => {
  const { ChildrenState } = props;
  console.log(ChildrenState);

  return (
    <>
      <div className={styles.IntContainer}>
        <p className={styles.IntTitle}>系统简介</p>
        {
          arr?.map((item,index) => {
           if( item.name===ChildrenState){
            return<div key={index}>
           <p className={styles.IntContent}>
             {item.content}
            </p>
            <img width={1204} src={item.src} alt="" />
           </div>
           }
          //  return<>
          //   <p className={styles.IntContent}>
          //     "本系统通过数字孪生技术，整合了矿山各个系统的数据源，将矿山运维数据、IoT设备数据与三维矿山空间数据相结合，对开发区、运输区和储藏区进行了统一管理， 提升了数据维度，实现了更加直观、更加精细化的矿山管理，全面提升了矿山的管理水平。"
          //   </p>
          //   <img width={1204} src={introduce} alt="" />
          // </>
          })
        }

      </div>
    </>
  )
}
export default Introduction

const arr = [
  {
    name: "智慧矿山",
    content: "本系统通过数字孪生技术，整合了矿山各个系统的数据源，将矿山运维数据、IoT设备数据与三维矿山空间数据相结合，对开发区、运输区和储藏区进行了统一管理， 提升了数据维度，实现了更加直观、更加精细化的矿山管理，全面提升了矿山的管理水平。",
    src:threemine
  },
  {
    name: "乡村振兴",
    content: "乡村振兴数字孪生系统是基于数字技术和大数据等技术手段，对乡村振兴工作进行模拟、分析和预测的一种系统。该系统通过搜集、整合和分析各类乡村振兴相关数据，包括人口、经济、土地利用、农业生产、农村环境等方面的数据，构建出一个与实际乡村情况相对应的数字模型。",
    src:village
  },
  {
    name: "智慧园区",
    content: "高度融合园区多种数据资源，运用3D技术制作园区三维模型，对园区产业、资产、基础设施、能效、安防等领域的关键指标进行综合监测分析，打造智慧园区管理一张图，实现更加高效科学的园区管理，全面提升园区管理水平。",
    src:park1
  },
  {
    name: "智慧校园",
    content: "是一种基于新一代智能互联网技术的校园管理模式，以数字孪生技术为基础，利用云计算、大数据、人工智能等技术实现智能化管理。通过数字化数据的采集、处理、分析和应用，实现校园的信息化、智能化和无人化，提高教育教学质量和服务效率。该系统可以实现学生管理、教职工管理、教学管理、设施设备管理、安全管理、校园服务等多个方面的功能。它可以帮助学校提高教育教学水平，提高教育信息化发展水平，建立更加智慧、安全、高效的校园管理模式，促进学校信息化建设。",
    src:campusone
  },
  {
    name: "智慧水电站",
    content: "数字孪生智慧水电站是一种基于水电站运营数据及物理模型的数字孪生系统，通过将实际水电站的运营数据与其物理模型结合，可以实现对水电站的实时监测和预测，提高水电站的生产效率和安全性。",
    src: wisdomwater1
  },
]