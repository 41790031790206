import { Suspense, useEffect } from 'react'
import { createRoot } from 'react-dom/client';
import routes from 'virtual:generated-pages-react';
import { BrowserRouter, useNavigate, useRoutes } from "react-router-dom";

const App = () => {
    const navigate = useNavigate();

    useEffect(() => {
        if (location.pathname == '/') {
            navigate('/home')
        } else {
            navigate(location.pathname)
        }
    }, [])

    return (
        <Suspense fallback={<p>Loading...</p>}>
            {useRoutes(routes)}
        </Suspense>
    )
}

const container: any = document.getElementById('root');
const root = createRoot(container);
root.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>
)
