
import React, { useState, useEffect } from 'react'
import styles from './index.module.less'
import { img12, img13, img14 } from '@/assets/workOut/image'
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import classNames from 'classnames'
import { Carousel } from 'antd';
import _ from 'lodash';
import SelfCarousel from '@/components/SelefCarousel';

// 实践案例

const SixthBlock: React.FC<any> = () => {
    const [buttonState, setButtonState] = useState<any>()
    const [dataRegroup, setDataRegroup] = useState<any>()

    useEffect(() => {
        setDataRegroup(_.chunk(list, 3))
    }, [])

    return <>
        <div className={styles.sixthBlock}>
            <div className={styles.title}>实践案例</div>
            <div className={styles.body}>
                <SelfCarousel dots={false}>
                    {
                        dataRegroup?.map((list: any) => {
                            return <>
                                <div className={styles.centerBox}>
                                    {
                                        list?.map((item: any) => {
                                            return <>
                                                <div
                                                    className={classNames(
                                                        styles.item,
                                                        buttonState === item.name && styles.activeButton
                                                    )}

                                                >
                                                    <img src={item.img} />
                                                    <div className={classNames(
                                                        styles.button,
                                                        buttonState === item.name && styles.activeButton
                                                    )}
                                                        onClick={() => buttonState === item.name ? setButtonState(null) : setButtonState(item.name)}>
                                                        {item.name}
                                                    </div>
                                                </div>
                                            </>
                                        })
                                    }
                                </div>
                            </>
                        })
                    }
                </SelfCarousel>
            </div>
        </div>
    </>
}

const list = [
    {
        img: img12,
        name: '成都',
    }, {
        img: img13,
        name: '深圳',
    }, {
        img: img14,
        name: '武汉',
    }, {
        img: img12,
        name: '成都',
    }, {
        img: img13,
        name: '深圳',
    }, {
        img: img14,
        name: '武汉',
    }, {
        img: img12,
        name: '成都',
    }, {
        img: img13,
        name: '深圳',
    }, {
        img: img14,
        name: '武汉',
    },
]
export default SixthBlock