import React from 'react'
import { homeBg, fgVideo } from '@/assets/image'
import { Carousel } from 'antd';
import { Menu } from "@/components/menu";
import styles from './index.module.less'

const OnePage: React.FC<any> = () => {
  return (

    <>
      <div className={styles.onePageContainer}>
        <div className={styles.titleBox}>
          <div className={styles.bigTitle} >数字孪生</div>
          <div className={styles.context} >
            <p>飞戈数字孪生三维可视化平台全国交付中心</p>
            <p>推动数字化转型实现智能化升级</p>
          </div>
        </div>

        <Carousel autoplay autoplaySpeed={16000} className={styles.carousel}>
          <div>
            <video src="https://cloud-assets-brwq.oss-cn-heyuan.aliyuncs.com/wutu417/uploads/20230601/4aca6bd28aa5ad36ad6e247f37eb7453.mp4" autoPlay loop muted style={{ width: "100%", height: '954px', objectFit: "cover" }}></video>
            {/* <img src={homeBg} className={styles.homeBg} /> */}
          </div >
          <div>
            {/* <img src={homeBg} className={styles.homeBg} /> */}
            <video src="https://cloud-assets-brwq.oss-cn-heyuan.aliyuncs.com/wutu417/uploads/20230601/4aca6bd28aa5ad36ad6e247f37eb7453.mp4" autoPlay loop muted style={{ width: "100%", height: '954px', objectFit: "cover" }}></video>
          </div >
          <div>
            {/* <img src={homeBg} className={styles.homeBg} /> */}
            <video src="https://cloud-assets-brwq.oss-cn-heyuan.aliyuncs.com/wutu417/uploads/20230601/4aca6bd28aa5ad36ad6e247f37eb7453.mp4" autoPlay loop muted style={{ width: "100%", height: '954px', objectFit: "cover" }}></video>
          </div >
          <div>
            <video src="https://cloud-assets-brwq.oss-cn-heyuan.aliyuncs.com/wutu417/uploads/20230601/4aca6bd28aa5ad36ad6e247f37eb7453.mp4" autoPlay loop muted style={{ width: "100%", height: '954px', objectFit: "cover" }}></video>
            {/* <img src={homeBg} className={styles.homeBg} /> */}
          </div >
        </Carousel>
        <Menu />
      </div >
    </>

  )
}
export default OnePage