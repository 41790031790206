import React from 'react'
import styles from './index.module.less'

const Scene:React.FC<any>=()=> {
    const [colorstate,setColorState]=React.useState('con1')
  const onchange=(item:any)=>{
      item.key===colorstate?setColorState(colorstate):setColorState(item.key)
  }
  return (
   <>
    <div className={styles.conContainer}>
        <div className={styles.conBigTitle}>丰富的应用场景</div>
        <div className={styles.conTitle}>六大功能沉浸式提升用户体验</div>
        <div className={styles.conContent}>
          {
            conArr?.map((item) => {
              return <>
                <div className={styles.conBox} key={item.key} onMouseEnter={()=>onchange(item)} style={{backgroundColor:item.key===colorstate?'rgba(62, 70, 107, 1)':''}}>
                  <div style={{ fontSize: '24px', fontWeight: 800, lineHeight: '10px', margin: '0px auto 44px' }}>{item.title}</div>
                  <p className={styles.conContent}>{item.content}</p>
                </div>
              </>
            })
          }
        </div>
      </div>
   </>
  )
}
export default Scene
const conArr = [
    {
      key: 'con1',
      title: "品牌宣传",
      content: '流量时代下，每一个品牌都应该拥有一个独立的元宇宙空间，将品牌场景化，带有独立主张和文化特性，以更好地构筑品牌精神堡垒。',
    },
    {
      key: 'con2',
      title: "产品体验",
      content: '利用AR/VR/XR等多设备入口，更好地对产品进行全方位的展示，并可以利用高交互性来强化用户对于产品的认知与了解，加深用户对于产品的印象与信赖度。',
    },
    {
      key: 'con3',
      title: "交易分享",
      content: '基于元宇宙云展厅的经济系统，支持多种形式的交易方式，以及完整的交易页面和流畅的交易流程，搭建消费者从了解到购买再到分享的全链路体验。',
    },
    {
      key: 'con4',
      title: "娱乐社交",
      content: '用户以数字人的身份在元宇宙云展厅中无障碍地社交娱乐，实现多形式的沟通方式，满足不同层次的社交需求，搭建完善的元宇宙社交圈，加深用户黏性。',
    },
    {
      key: 'con5',
      title: "学习创造",
      content: '用户可以利用元宇宙云展厅，持续地进行学习与价值输出，打通知识获取、知识创造、知识NFT化的Web3.0时代内容创作闭环。',
    },
    {
      key: 'con6',
      title: "数据分析",
      content: '元宇宙云展厅将实现实时动态数据归集，多系统用户信息汇总分析，信息智能推荐等功能。为用户提供更好的更好的浏览体验，为品牌带来更加精准的用户画像。',
    },
  ]