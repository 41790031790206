import React from 'react'
import { modelBg, service_Bg, metContentBg } from '@/assets/image'
import styles from './index.module.less'

const Exhibition: React.FC<any> = () => {
    const [state, setState] = React.useState(modelBg)
    const [colorState, setColorState] = React.useState("企业元宇宙")
    const onChangePng = (item: any) => {
        setColorState(item.name)
        setState(item.src)
    }
    return (
        <>
            <div className={styles.aesContainer}>

                <div className={styles.aesBigTitle}>{colorState}</div>
                <div className={styles.aesContent}>
                    {
                        aesArr?.map(item => {
                           if(item.name===colorState){
                            return <>
                            <div className={styles.leftBox} >
                                <div className={styles.aesTitle}>
                                    <p> {item.content}</p>
                                </div>
                                <div style={{ width: '950px', height: '480px', overflow: 'hidden' }}><img src={item.src} /></div>
                                <div className={styles.imgMask}></div>
                                <div className={styles.box}>
                                </div>
                            </div>
                        </>
                           }
                        })
                    }
                    <div className={styles.rightBox}>
                        {
                            aesArr?.map(item => {
                                return <>
                                    <div key={item.key} className={colorState === item.name ? styles.aesBoxActive : styles.aesBox} onClick={() => onChangePng(item)} >
                                        <span style={{ fontStyle: 'italic', fontSize: '32px', fontWeight: 800, marginRight: '16px' }}>{item.name}</span>
                                        <div className={styles.line}></div>
                                    </div>
                                </>
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
export default Exhibition
const aesArr = [
    {
        key: 'L1',
        name: '企业元宇宙',
        src: metContentBg,
        content: "企业元宇宙为企业提供了一个虚拟的数字平台，使得员工、合作伙伴和客户可以在其中进行工作、交流和合作。企业元宇宙可以提供虚拟会议室、虚拟办公室等功能，使得远程办公更加便利和真实。员工可以通过虚拟现实设备，如头戴式显示器或手持设备，与其他团队成员进行实时互动，共同解决问题和完成任务。"
    },
    {
        key: 'L2',
        name: '元宇宙数字展厅',
        src: service_Bg,
        content: "元宇宙数字展厅是一个虚拟现实的展示空间，它可以让参观者通过数字技术进入一个全新的互动世界，感受到数字化时代的无限可能性。在元宇宙数字展厅中，参观者可以与虚拟的展品、空间、人物等进行互动，并且可以通过手势、语音等方式进行控制和操作。这种数字展厅可以被用来展示各种物品和产业，如艺术品、文物、建筑设计、科技创新等等。"
    },
    {
        key: 'L3',
        name: '文旅元宇宙',
        src: modelBg,
        content: "在文旅元宇宙中，人们可以探索、学习、参与各种文化活动，同时还能参观各种旅游景点、历史遗迹和名胜古迹等。此外，文旅元宇宙还可以运用现代科技手段，如虚拟现实和增强现实等，打造出更加真实、立体的文旅体验。文旅元宇宙具有开放性和互动性，可以让人们跨越时空的限制，体验到不同的文化和旅游场景，进而促进文化交流和旅游发展。"
    },
    {
        key: 'L4',
        name: '文博元宇宙想',
        src: modelBg,
        content: "博物馆元宇宙通过数字化技术，将文物、艺术品、展品等转化为虚拟模型，并且添加了立体音效、交互式效果等，让参观者可以在虚拟博物馆中漫游、互动、探索和学习。这种虚拟化的展览形式，扩展了博物馆的展览空间，使得观众可以随时随地方便地参观。"
    },
    {
        key: 'L5',
        name: '校园元宇宙',
        src: modelBg,
        content: "在校园元宇宙中，他们可以参加虚拟课程、虚拟讲座、虚拟实验等，实现在家中就能够实时体验到校园内的教学、文化和社交等全方位的服务和体验。校园元宇宙还可以为学生提供社交聚会、校内活动和组织管理等服务，极大地提升学生的参与、互动和沟通能力。同时，校园元宇宙可以帮助学校提升品牌形象和校园文化，促进学校的发展和招生。"
    },
]