import { Col, Row, Statistic } from 'antd';
import React from 'react';
import { Carousel } from 'antd';
import CountUp from 'react-countup'
import styles from './index.module.less'
import { homeBg, fgVideo } from '@/assets/image'
import { LoginBox } from '@/components/loginbox';
import { CloseOutlined } from '@ant-design/icons'
// import {Video} from '@/components/video'

const formatter = (value: any) => <CountUp end={value} separator="," />
// 飞戈数字介绍
const FgIntroduce = () => {
  const [state, setState] = React.useState(false)
  const [videoState, setVideoState] = React.useState(false)
  const [codeState, setCodeState] = React.useState(false)

  // 监听滚动事件
  window.addEventListener('scroll', function () {
    // 获取滚动的高度
    const scrollTop = window.scrollY || document.documentElement.scrollTop;

    // 执行其他操作，例如更新UI、触发动画等
    if (scrollTop >= 700 && scrollTop <= 1800) {
      setState(true)
    } else {
      setState(false)
    }
  });
  // 登录状态
  const onOpenVideo = () => {
    setCodeState(true)  
  }

  // console.log(videoState,"video");
  // 视频关闭
  const onClose = () => {
    setVideoState(false)
  }
  // console.log(codeState);
  // 监听视频状态
  const getVideo=(val:any)=>{
    setVideoState(val)
  }
  const getCode=(val:any)=>{
  setCodeState(val)
  }
  return (
    <>
      <div className={styles.FgContainer} style={{ height: '959px' }}>
        <div className={styles.fgContent}>
          <div className={styles.headerLeft} onClick={() => onOpenVideo()}>
            <img className={styles.fgVideo} src={fgVideo} />
            <div className={styles.overlay}></div>
          </div>
          <div className={styles.headerRight}>
            <p style={{ fontSize: "45px", fontWeight: 800, }}>飞戈数字</p>
            <div style={{ fontSize: "20px", lineHeight: '0.8', }}>
              <p>我们是一家以GIS、BIM、IOT、5G、图形渲染、三维建模</p>
              <p>为基础的高科技公司，以数字底座为支撑，数字可视化呈现，</p>
              <p>智能一张图决策，深度赋能国内各个领域和产业。飞戈数字孪</p>
              <p>生三维可视化平台全国交付中心，助力政企数字化转型，实现</p>
              <p>智能化升级。构建中国智慧数字孪生新业态，让交互更自由，</p>
              <p>让数据有生命,让智能更简单。</p>
            </div>
          </div>
        </div>
        <div style={{ display: 'grid', gridTemplateColumns: 'auto auto auto auto', gap: '284px', position: 'absolute', top: "1600px" }}>
          <div style={{ width: '2px', height: '160px', backgroundImage: 'linear-gradient(to top,rgba(255,255,255,0)20%,rgba(255,255,255,.4)60%,rgba(255,255,255,0)80%)', marginLeft: '-10px' }}></div>
          <div style={{ width: '2px', height: '160px', backgroundImage: 'linear-gradient(to top,rgba(255,255,255,0)20%,rgba(255,255,255,.4)60%,rgba(255,255,255,0)80%)', marginLeft: '-10px' }}></div>
          <div style={{ width: '2px', height: '160px', backgroundImage: 'linear-gradient(to top,rgba(255,255,255,0)20%,rgba(255,255,255,.4)60%,rgba(255,255,255,0)80%)', marginLeft: '10px' }}></div>
          <div style={{ width: '2px', height: '160px', backgroundImage: 'linear-gradient(to top,rgba(255,255,255,0)20%,rgba(255,255,255,.4)60%,rgba(255,255,255,0)80%)', }}></div>
        </div>
        <div className={styles.fgFoot} id='rollNum'>
          <div> <p style={{ fontSize: '20px', textAlign: 'center' }}>服务行业</p> </div>
          <div></div>
          <div> <p style={{ fontSize: '20px', textAlign: 'center' ,marginLeft:'-10px'}}>落地项目</p></div>
          <div></div>
          <div> <p style={{ fontSize: '20px', textAlign: 'center',marginLeft:'30px' }}>处理数据</p></div>
          <div></div>
          <div> <p style={{ fontSize: '20px', textAlign: 'center' ,marginLeft:'30px'}}>合作伙伴</p></div>
          <div></div>
          <div> <p style={{ fontSize: '20px', textAlign: 'center' }}>实施团队</p></div>
        </div>
        {state &&
          <>
            <div className={styles.fgFoot1} id='rollNum'>
              <div>
                {/* <p style={{ fontSize: '20px', textAlign: 'center' }}>服务行业</p> */}
                <p style={{ fontSize: '36px', fontWeight: 600, textAlign: 'center', fontStyle: 'italic' }}><span > <Statistic value={30} formatter={formatter} suffix={'+'} /></span></p>
              </div>
              <div></div>
              <div>
                {/* <p style={{ fontSize: '20px', textAlign: 'center' }}>落地项目</p> */}
                <p style={{ fontSize: '36px', fontWeight: 600, textAlign: 'center', fontStyle: 'italic' }}><span > <Statistic value={100} formatter={formatter} suffix={'+'} /></span></p>
              </div>
              <div ></div>
              <div>
                {/* <p style={{ fontSize: '20px', textAlign: 'center' }}>处理数据</p> */}
                <p style={{ fontSize: '36px', fontWeight: 600, textAlign: 'center', fontStyle: 'italic' }}><span > <Statistic value={200} formatter={formatter} suffix={'TB+'} /></span></p>
              </div>
              <div ></div>
              <div>
                {/* <p style={{ fontSize: '20px', textAlign: 'center' }}>合作伙伴</p> */}
                <p style={{ fontSize: '36px', fontWeight: 600, textAlign: 'center', fontStyle: 'italic' }}><span ><Statistic value={60} formatter={formatter} suffix={'+'} /></span> </p>
              </div>
              <div ></div>
              <div>
                {/* <p style={{ fontSize: '20px', textAlign: 'center' }}>实施团队</p> */}
                <p style={{ fontSize: '36px', fontWeight: 600, textAlign: 'center', fontStyle: 'italic' }}><span > <Statistic value={30} formatter={formatter} suffix={'+'} /></span> </p>
              </div>

            </div>
          </>
        }
        {!state &&
          <>
            <div className={styles.fgFoot} id='rollNum'>
              <div>
                {/* <p style={{ fontSize: '20px', textAlign: 'center' }}>服务行业</p> */}
                <p style={{ fontSize: '36px', fontWeight: 600, textAlign: 'center', fontStyle: 'italic' }}><span > <Statistic value={40} formatter={formatter} suffix={'+'} /></span></p>
              </div>
              <div ></div>
              <div>
                {/* <p style={{ fontSize: '20px', textAlign: 'center' }}>落地项目</p> */}
                <p style={{ fontSize: '36px', fontWeight: 600, textAlign: 'center', fontStyle: 'italic' }}><span > <Statistic value={3000} formatter={formatter} suffix={'+'} /></span></p>
              </div>
              <div ></div>
              <div>
                {/* <p style={{ fontSize: '20px', textAlign: 'center' }}>处理数据</p> */}
                <p style={{ fontSize: '36px', fontWeight: 600, textAlign: 'center', fontStyle: 'italic' }}><span > <Statistic value={300} formatter={formatter} suffix={'TB+'} /></span></p>
              </div>
              <div ></div>
              <div>
                {/* <p style={{ fontSize: '20px', textAlign: 'center' }}>合作伙伴</p> */}
                <p style={{ fontSize: '36px', fontWeight: 600, textAlign: 'center', fontStyle: 'italic' }}><span ><Statistic value={600} formatter={formatter} suffix={'+'} /></span> </p>
              </div>
              <div ></div>
              <div>
                {/* <p style={{ fontSize: '20px', textAlign: 'center' }}>实施团队</p> */}
                <p style={{ fontSize: '36px', fontWeight: 600, textAlign: 'center', fontStyle: 'italic' }}><span > <Statistic value={100} formatter={formatter} suffix={'+'} /></span> </p>
              </div>

            </div>
          </>
        }
      </div >
      {videoState &&
        <div className={styles.videoMain}>
          <div className={styles.closeIcon} onClick={() => { onClose() }} style={{ color: '#fff' }}><CloseOutlined rev={undefined}/></div>
          <video src="https://cloud-assets-brwq.oss-cn-heyuan.aliyuncs.com/wutu417/uploads/20230601/4aca6bd28aa5ad36ad6e247f37eb7453.mp4" controls autoPlay></video>
        </div>
      }
      {
       codeState &&<LoginBox codeState={codeState}  getVideo={getVideo} getCode={getCode}/>
      }
    </>
  )
}
export default FgIntroduce