import React from 'react';
import styles from './index.module.less';

const Title: React.FC<any> = () => {
    return <>
        <div className={styles.titleBox}>
            <div>虚拟空间</div>
            <div>元宇宙搭建</div>
            <div>虚拟空间</div>
        </div>
    </>
}

export default Title