import React from 'react'
import { feature1, feature2, feature3, feature4, feature5,arrow } from '@/assets/image'
import { Tabs } from 'antd';
import type { TabsProps } from 'antd';
import styles from './index.module.less'

const Feature: React.FC<any> = () => {
  const [state,setState]=React.useState("1")
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: `数字孪生地球`,
      children: <Children state={state}/>,
    },
    {
      key: '2',
      label: `数字孪生城市/园区`,
      children: <Children state={state}/>,
    },
    {
      key: '3',
      label: `数字孪生建筑`,
      children: <Children state={state}/>,
    },
    {
      key: '4',
      label: `数字孪生设备`,
      children: <Children state={state}/>,
    },
    {
      key: '5',
      label: `数字孪生IT`,
      children: <Children state={state}/>,
    },
  ];
  const onChange = (key: string) => {
    setState(key)
  };
  return (
    <>
      <div className={styles.feaContainer}>
        <div className={styles.feaTitle}>功能</div>
        <div className={styles.feaContent}>
        <Tabs defaultActiveKey="fea1" items={items} onChange={onChange}  centered/>
          {/* {
            feaArr?.map((item => {
              return <>
                <div className={styles.feaBox} key={item.key} style={{
                  background: `url(${item.src})`,
                  backgroundSize: '100% 100%',
                  backgroundRepeat: 'no-repeat'
                }}>
                  <div className={styles.littleBox}>
                    <div className={styles.boxTitle}>{item.title}</div>
                    <div className={styles.boxContent}>{item.content}</div>
                  </div>
                </div>
              </>
            }))
          } */}
        </div>
      </div>
    </>
  )
}
const Children: React.FC<any> = (props:any) => {
  const {state}=props
  console.log("state",state);
  
  return<>
{
 state&&
  <>
    <div className={styles.sonContainer}>
      <div className={styles.sonLeft}>
      <div> <p style={{fontSize:'16px'}}>{feaArr[state-1].content}</p></div>
      <div>  <img src={arrow } width={'48px'} /></div>
    </div>
    <div className={styles.sonRight}><img width={'916px'} height={'549px'} src={feaArr[state-1].src} alt="" /></div>
  </div></>
}
{
  !state&&<>无</>
}
  </>
  }
export default Feature

const feaArr = [
  {
    key: 'fea1',
    title: '数字孪生地球',
    content: '对宇宙空间、地球以及其他星体等要素及运行态势进行数字孪生。原生兼容全球范围各类地理信息数据，可对全球地形地貌数据进行真实复现，具备通真的环境效果及渲染特效、丰富的空间辅助信息显示、支持星历精确推算星体位置显示，实现宇宙环境、天体位置、大气光照等环境效果模拟。',
    src: feature1
  },
  {
    key: 'fea2',
    title: '数字孪生城市/园区',
    content: '对大规模城市、重点园区、部件等全要表及运行态势进行数字孪生。预置海量城市基座数据，支持城市倾斜摄影数据、无人机航拍数据集成，支撑大规模数字孪生城市场景快速构建，对城市、园区全景进行真实复现;支持各类传感器监测数据集成，部件全要素运行态势全面感知;原生支持城市智能化全尺度渲染，可实现从宇宙空间到大规模城市、再到园区建筑、装备细节的无级放缩，流畅展现。',
    src: feature2
  },
  {
    key: 'fea3',
    title: '数字孪生建筑',
    content: '对建筑外观材质、层级结构、内部管线等要素及运行态势进行数字孪生。支持实时高通真细节效果渲染，高度还原建筑的外部环境、外观材质、纹理细节，支持楼宇层级结构剖分，对内部层级结管线等要素进行立体仿真展示，并可进行点选查询、视点调整及场景切换;支持集成楼宇loT数据，真实完整地复现整个楼宇运行态势。',
    src: feature3
  },
  {
    key: 'fea4',
    title: '数字孪生设备',
    content: '对设备外观材质、机械结构、复杂动作等要素及运行态势进行数字孪生。支持设备精密细节显示高度还原设备的外形、材质、纹理细节;支持内部零部件、管线、传感器等复杂结构定义，高度还原设备复杂机械结构;支持接入实时/历史数据、真实/模拟数据，动态驱动设备模型姿态及复杂动作，真实再现设备的运转过程及工作原理。',
    src: feature4
  },
  {
    key: 'fea5',
    title: '数字孪生IT',
    content: '支持对业务流程、IT架构、关联关系等要素及运行态势进行数字孪生。支持从地理空间分布维度和逻辑层级结构维度，通过多种可视化分析方式，对各网络节点的地域分布、网络状态、业务流量状态等信息进行可视化分析，并对网络异常情况进行可视化告警，帮助用户综合掌控跨地域、大范围IT态势，快速识别网络异常情况。',
    src: feature5
  },
]