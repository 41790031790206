import React from "react";
import styles from './index.module.less'
import Card from "../components/card";
import { case1, case2, case3, case4 } from "@/assets/image";

// 智慧矿山
const SmartMine: React.FC<any> = () => {
    return <>
        <Card list={list} />
    </>
}
const list = [
    [
        {
            key: 'case1', video: '', img: case4
        }, {
            key: 'case2', video: '', img: case2
        }, {
            key: 'case3', video: '', img: case1
        }, {
            key: 'case4', video: '', img: case3
        }, {
            key: 'case5', video: '', img: case1
        }, {
            key: 'case6', video: '', img: case1
        }],
    // [
    //     {
    //         key: 'case1', video: '', img: case1
    //     }, {
    //         key: 'case2', video: '', img: case2
    //     }, {
    //         key: 'case3', video: '', img: case3
    //     }, {
    //         key: 'case4', video: '', img: case4
    //     }, {
    //         key: 'case5', video: '', img: case1
    //     }, {
    //         key: 'case6', video: '', img: case3
    //     }
    // ],
    // [
    //     {
    //         key: 'case1', video: '', img: case1
    //     }, {
    //         key: 'case2', video: '', img: case2
    //     }, {
    //         key: 'case3', video: '', img: case3
    //     }, {
    //         key: 'case4', video: '', img: case4
    //     }, {
    //         key: 'case5', video: '', img: case1
    //     }, {
    //         key: 'case6', video: '', img: case3
    //     }
    // ],
    // [
    //     {
    //         key: 'case1', video: '', img: case1
    //     }, {
    //         key: 'case2', video: '', img: case2
    //     }, {
    //         key: 'case3', video: '', img: case3
    //     }, {
    //         key: 'case4', video: '', img: case4
    //     }, {
    //         key: 'case5', video: '', img: case1
    //     }, {
    //         key: 'case6', video: '', img: case3
    //     }
    // ]


]
export default SmartMine