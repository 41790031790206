import React from 'react'
import styles from './index.module.less'
import { img6, img7, img8, img9, img10, img11 } from '@/assets/workOut/image'

// 产品功能
const FourthBlock: React.FC<any> = () => {
    return <>
        <div className={styles.fourthBlock}>
            <div className={styles.title}>
                产品功能
            </div>
            <div className={styles.body}>
                {
                    imgList?.map((item: any) => {
                        return <>
                            <div className={styles.itemBox}
                                style={{
                                    background: `url(${item.img}) no-repeat`,
                                    backgroundSize: '100% 100% '
                                }}
                            >
                                <div className={styles.imgMask}></div>
                                <div style={{zIndex:2}}>{item.name}</div>
                                <div style={{fontSize:16,zIndex:2}}>{item.content}</div>
                            </div>
                        </>
                    })
                }
            </div>
        </div>
    </>
}

const imgList = [
    {
        name: '城市展现',
        content: '城市展现 城市地标、地形地貌',
        img: img6,
    }, {
        name: '区位展现',
        content: '区位展现 城市区位、定位、规划、配套',
        img: img7,
    }, {
        name: '项目展现',
        content: '项目展现 项目建筑形态、园林景观还原',
        img: img8,
    }, {
        name: '景观展现',
        content: '园林景观、商业街区漫游',
        img: img9,
    }, {
        name: '销控选房',
        content: '房源信息分类筛选及快速定位',
        img: img10,
    }, {
        name: '户型鉴赏',
        content: '样板房三维截面、户型漫游',
        img: img11,
    },
]

export default FourthBlock