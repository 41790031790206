import React from 'react'
import { friend1, friend2, friend3, } from '@/assets/image'
import styles from './index.module.less'

const BusinessPartner: React.FC<any> = () => {

  return (
    <>
      <div className={styles.friendContainer}>
        <p className={styles.friendTitle}>合作伙伴</p>
        <p className={styles.friendPage}>让合作伙伴成功是我们唯一的要求</p>
        <div className={styles.friendContent}>
          <div className={styles.lentMask}></div>
          <div className={styles.rightMask}></div>
          <div className={styles.line_odd}>
            <img width={1560} style={{ marginRight: 20, }} src={friend1} />
            <img width={1560} src={friend1} />
          </div>
          <div className={styles.line_event}>
            <img width={1560} style={{ marginRight: 20 }} src={friend2} />
            <img width={1560} src={friend2} />
          </div>
          <div className={styles.line_odd}>
            <img width={1560} style={{ marginRight: 20 }} src={friend3} />
            <img width={1560} src={friend3} />
          </div>
          {/* <img className={styles.friendAnimation} width={1560} src={friend1} />
          <img className={styles.friendAnimation} width={1560} src={friend2} />
          <img className={styles.friendAnimation} width={1560} src={friend3} /> */}

        </div>
      </div>
    </>
  )
}
export default BusinessPartner