import React from 'react'
import MetOnePage from './metonePage'
import Exhibition from './exhibition'
import CoreFunction from './corefunction'
import Scene from './scene'
import LastBlock from '../lastBlock'

 const Metaverse:React.FC<any>=()=> {
  return (
  <>
  <div>
    {/* 第一页 */}
    <MetOnePage/>
    {/* 线上展会 */}
    <Exhibition/>
    {/* 核心功能 */}
    <CoreFunction/>
    {/* 应用场景 */}
    <Scene/>
    {/* 底图 */}
    <LastBlock/>
  </div>
  </>
  )
}
export default Metaverse