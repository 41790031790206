import React from "react";
import styles from './index.module.less'
import { Input } from "antd";
import axios from 'axios'
import { SelfInput } from "@/components/SelfInput";

const InputBlock = () => {
    const [name,setName]=React.useState('')
    const [phone,setPhone]=React.useState('')
    // console.log(name,phone);
    

    const buttonSubmit = () => {
        // 如果验证失败，可以提示用户手机号或验证码错误
        axios.post('https://trtc.iyunwu.cn/admin/app/web/insentCustom', {
            name: name, phone: phone, content: ''
        }).
        then(function (response) {
            console.log(response);
        })
    }
    const getName = (event:any) => {
        setName(event.target.value);
    }
    const getPhone = (event:any) => {
        setPhone(event.target.value);
    }

    return <>
        <div className={styles.container}>
            <div className={styles.titleBox}>
                <div className={styles.title}>数字孪生, 实体经济转型新方式</div>
                <div className={styles.subTitle}>获取行业方案，共赢时代机遇</div>
            </div>
            <div className={styles.inputBox}>
                <SelfInput
                    name='name'
                    placeholder='请输入您的姓名'
                    message='名字不能为空'
                    style={{ width: 456, height: 60 }}
                    onChange={getName}
                />
                <SelfInput
                    name="phone"
                    placeholder="请输入您的电话"
                    message='电话不能为空'
                    style={{ width: 456, height: 60 }}
                    onChange={getPhone}
                />
                <div className={styles.button}
                    onClick={() => buttonSubmit()}
                >
                    免费获取
                </div>
            </div>
        </div>

    </>
}
export default InputBlock