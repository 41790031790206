import React from "react";
import styles from './index.module.less'
import Card from "@/pages/home/successCase/items/components/card";
import { case1, case2, case3, case4,case5,case6 } from "@/assets/image";

// 经典案例
const Main: React.FC<any> = () => {


    return <>
        <Card list={list} />
    </>
}

const list = [
    [
        {
            key: 'case1', video: '', img: case2,name:'智慧矿山'
        }, {
            key: 'case2', video: '', img: case2,name:'乡村振兴'
        }, {
            key: 'case3', video: '', img: case3,name:'智慧园区'
        }, {
            key: 'case4', video: '', img: case4,name:'智慧校园'
        }, {
            key: 'case5', video: '', img: case6,name:'智慧水电站'
        }, {
            key: 'case6', video: '', img: case6,name:'智慧地产'
        }],
    
]

export default Main