import React from "react";
import styles from './index.module.less'
import {imgBg} from "@/assets/workOut/image";

const SecondBlock: React.FC<any> = () => {
    return <>
        <div className={styles.secondBlock}>
            <div className={styles.leftBox}>
                <div className={styles.title}>
                    无边际，厘米级<br />
                    全开放，多领域
                </div>
                <div className={styles.line} />
                <div className={styles.content}>
                    无边际：超千平方公里GIS数据实时动态运算<br />
                    厘米级： 超高精BIM模型1: 1复刻项目全盘解构项目内外结构<br />
                    全开放：360°全方位自由视角场景漫游沉浸式体验多场景交互<br />
                    多领域： 定制化功能框架 为多个行业输出解决方案<br />
                </div>
            </div>
            <div className={styles.rightBox}>
                <img src={imgBg} />
            </div>
        </div >
    </>
}

export default SecondBlock