import React, { useState } from 'react'
import { aboutBg_2, time_1, time_2, leftAboutArrow, RightAboutArrow, } from '@/assets/image'
import styles from './index.module.less'

const DevelopTime: React.FC<any> = () => {
  const [state, setState] = useState(0)
  const arr = devArr.concat(devArr)
  const add = () => {
    console.log(arr);

    state >= arr.length - 3 ? setState(0) : setState(state + 1)
  }
  const reduce = () => {
    state <= 0 ? setState(arr.length - 3) : setState(state - 1)
  }
  return (
    <>
      <div className={styles.devContainer} >
        <div className={styles.devTitle}>发展历程</div>
        <div>
          <div className={styles.devContent}>
            <div className={styles.devBoxImgLeft}>  < img src={leftAboutArrow} onClick={() => reduce()} /></div>
            <div className={styles.devBox}>
              <div className={styles.boxTitle}>{arr[state].time}</div>
              <div className={styles.boxContent}>{arr[state].content}</div>
              <img src={arr[state].src} />
            </div>
            <div className={styles.devBox} >
              <div className={styles.boxTitle}>{arr[state + 1].time}</div>
              <div className={styles.boxContent}>{arr[state + 1].content}</div>
              <img src={arr[state + 1].src} />
            </div>
            <div className={styles.devBox}>
              <div className={styles.boxTitle}>{arr[state + 2].time}</div>
              <div className={styles.boxContent}>{arr[state + 2].content}</div>
              <img src={arr[state + 2].src} />
            </div>
            <div className={styles.devBoxImgRight}> <img src={RightAboutArrow} alt="" onClick={() => add()} /></div>
          </div>
        </div>
      </div>
    </>
  )
}
const devArr = [{
  key: 'dev1',
  time: '2013年',
  content: '7月,飞戈数字首次创立于深圳',
  src: time_1
}, {
  key: 'dev2',
  time: '2016年',
  content: '7月,飞戈数字总部在长沙成立',
  src: time_1
}, {
  key: 'dev3',
  time: '2017年',
  content: '11月,展厅异形屏汇报展示系统上线，实现国内领先的全面沉浸式超高分辨率展示',
  src: time_2
}, {
  key: 'dev4',
  time: '2018年',
  content: '12月,推动数字孪生技术新标准，结合VR虚拟技术研发与应用，全面开启3D数字体验时代',
  src: time_1
}, {
  key: 'dev5',
  time: '2019年',
  content: '1月,与新加坡集团跨国公司签署战略合作协议，一起携手开拓东南亚虚拟现实产品市场',
  src: time_2
},
{
  key: 'dev6',
  time: '2020年',
  content: <div style={{ marginTop: '-14px' }}><p></p>6月,楼盘CIM智慧营销系统上线，提供专业的楼盘展销一体化服务，并荣获地产最佳数字服务商<p >11月 与深圳龙华区市政规划局达成合作，展现数字图像技术与建筑的完美结合，推动城市客厅落地</p></div>,
  src: time_2
},
{
  key: 'dev6',
  time: '2021年',
  content: <div style={{ marginTop: '-14px' }}><p>1月,正式进军智慧城市、园区、景区等领域，并上线了对应的智慧系列垂直类产品 </p><p style={{ marginTop: '-20px' }}>6月,结合5G、IOT、AI、大数据等前沿领域，加大数字孪生平台的研发投入，推进大数据3D可视化落地应用</p></div>,
  src: time_2
},
{
  key: 'dev7',
  time: '2022年',
  content: '8月,优化和升级楼盘CIM系统，成功落地应用于成都蓝光黑钻、海棠故事、锦江金茂府、龙华保利等项目',
  src: time_2
},
{
  key: 'dev8',
  time: '2023年',
  content: '3月,成立元宇宙研发中心，研究AI虚拟人，为数字技术研发及运用，提供更多支持及市场开发探索',
  src: time_2
},
]

export default DevelopTime

