import React, { useState } from 'react';
import styles from './index.module.less'
import classNames from 'classnames'

//  合作权益
const CoperationEquity: React.FC<any> = () => {
    return <>
        <div className={styles.cooperationEquity}>
            <div className={styles.title}>合作权益</div>
            <div className={styles.body}>
                {list.map((item: any) => {
                    return <>
                        <div className={classNames( styles.items,)}
                            key={item.name}
                            
                        >
                            <div className={styles.name}>{item.name}</div>
                            <div className={styles.text}>{item.text}</div>
                        </div>
                    </>
                })}
            </div>
        </div>
    </>
}

const list = [
    {
        name: '技术支持',
        text: '提供图形引擎渲染、三维建模、BIM建筑信息模型、GIS地理信息、倾斜摄影、5G+AI算法、IOT物联网数据、数据中台等核心技术',
    }, {
        name: '产品赋能',
        text: '提供数字孪生引擎、图形引擎渲染、UE4-5等设计开发工具，增强合作伙伴自身产品竞争优势的同时，能够模拟和预测实体的行为和性能，赋能合作伙伴行业生态位提升。',
    }, {
        name: '资源共享',
        text: '深度与合作伙伴共享商务资源和机会线索，通过业务互推、客户转介绍、口碑推荐等方式，进一步提升订单的转化率，从而扩大飞戈数字在数字孪生、元宇宙等领域的影响力。',
    }, {
        name: '业务支持',
        text: '提供数字孪生引擎、图形引擎渲染、UE4-5等设计开发工具，增强合作伙伴自身产品竞争优势的同时，能够模拟和预测实体的行为和性能，赋能合作伙伴行业生态位提升。',
    },
]

export default CoperationEquity