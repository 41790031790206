import React, { useState } from 'react';
import styles from './index.module.less';
import classNames from 'classnames';
import { useFormik } from 'formik';

export const SelfInput: React.FC<any> = (props: any) => {
    const { id, name, placeholder, onSubmitValues, message,...otherProps } = props
    const [errorMessage, setErrorMessage] = useState<any>()

    const validate = (values: any) => {
        const error: any = {}
        if (!values[name]) {
            error[name] = message
            setErrorMessage(message)
        }
        return error

    }
    const formik = useFormik({
        initialValues: {
            /*  name: '',
             phone: '' */
            [name]: ''
        },
        validate,
        onSubmit: values => {
            // 拿到输入框的值
            onSubmitValues(values)
        },
    });

    return <>
        <div className={styles.inputBox}>
            <input
                id={id}
                name={name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder={placeholder}
                className={classNames(styles.input, formik.errors[name] && styles.validateInput)}
                {...otherProps}
            />
            {
                formik.touched[name] && formik.errors[name] ?
                    < div className={styles.validate} style={{ color: 'red' }}>{errorMessage}</div> : null
            }
        </div>
    </>
}

