import React from "react";;
import CountUp from 'react-countup'
import styles from './index.module.less'
import OnePage from "./onePage";
import ServiceAdvantage from "./serviceAdvantage";
import SuccessCase from "./successCase";
import FgIntroduce from "./fgIntroduce"
import CoreTechnology from "./coreTechnology";
import BusinessPartner from "./businessPartner";
import InputBlock from "./inputBlock";
import LastBlock from "./lastBlock";

const formatter = (value: any) => <CountUp end={value} separator="," />

const Home: React.FC<any> = (props: any) => {
    return <>
        <div className={styles.container}>
            {/* 飞戈数字首页 */}
            <OnePage />
            {/* 飞戈数字 */}
            <FgIntroduce />
            {/* 服务优势 */}
            <ServiceAdvantage />
            {/* 成功案例 */}
            <SuccessCase />
            {/* 核心技术 */}
            <CoreTechnology />
            {/* 合作伙伴 */}
            <BusinessPartner />
            {/* 姓名电话获取块 */}
            <InputBlock />
            {/* foot */}
            <LastBlock />
        </div>
    </>
}

export default Home;