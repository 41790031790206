import React from 'react'
import { aboutBg_1 } from '@/assets/image'
import { HoverMenu } from '@/components/hoverMenu'
import styles from './index.module.less'

const AboutOnePage: React.FC<any> = () => {
  return (
    <>
      <div className={styles.onePageContainer} style={{
        background: `url(${aboutBg_1})`,
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat'
      }}>
        {/* <HoverMenu /> */}
        <div className={styles.titleBox}>
          <div className={styles.bigTitle} >关于飞戈</div>
          <div className={styles.context} >
            <p>构建可感知的智能数字新世界，共创美好社会</p>
          </div>
        </div>
      </div >
    </>

  )
}
export default AboutOnePage