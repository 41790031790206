import React from 'react'
import styles from './index.module.less'
import { icon1, icon2, icon3 } from '@/assets/cooperation/image'

const CooperationType: React.FC<any> = () => {
    return <>
        <div className={styles.cooperationType}>
            <div className={styles.title}>合作类型</div>
            <div className={styles.body}>
                {
                    list.map((item: any) => {
                        return <>
                            <div className={styles.items} key={item.name}>
                                <img  className={styles.icon} src={item.icon} />
                                <div className={styles.name}>{item.name}</div>
                                <div className={styles.content}>{item.content}</div>
                            </div>
                        </>
                    })
                }
            </div>
        </div>
    </>
}

const list = [
    {
        icon: icon1,
        name: '行业伙伴',
        content: '诚邀服务于房地产、水利水电、乡村振兴、工程机械、展厅展示等行业的各类政企服务商、系统集成商、软硬件供应商、同行友商等企业或个人，开展行业渠道战略合作。',
    }, {
        icon: icon2,
        name: '高校伙伴',
        content: '诚邀全国各大高校对三维场景搭建、虚拟仿真培训、UE4-5游戏开发培训、3D教学课件等业务有需求的院校、教授、教师、学生提升相关产品与技术支持，签订校企合作。',
    }, {
        icon: icon3,
        name: '个体伙伴',
        content: '诚邀全社会招募独立模型师、UE4-5游戏开发师、三维场景设计师、航拍摄影师，以及技术开发人员，提供合作扶持与入驻激励，携手共同打造数字孪生三维可视化市场。',
    },
]

export default CooperationType