import React from 'react'
import { structure1, structure2, structure3, } from '@/assets/image'
import styles from './index.module.less'

const Structure: React.FC<any> = (props:any) => {
  const {ChildrenState}=props
  return (
    <>
      <div className={styles.strContainer}>
        <div className={styles.strTitle}>系统结构</div>
       {mineArr?.map((item,index)=>{
       if(item.name===ChildrenState){
        return<>
        <div className={styles.strLittleTitle}>{item.content}</div>
       <div className={styles.strContent}>
         {
          item?.arr?.map((item1,index) => {
             return <>
               <div className={styles.strBox} key={item1.key}>
                 <p> <img src={item1.src} /></p>
                 <p className={styles.boxTitle}>{item1.name}</p>
                 <p className={styles.boxContent}>{item1.content}</p>
               </div>
             </>
           })
         }
       </div>
       </>
       }
      //  return<>
      //  <div className={styles.strLittleTitle}>整个智慧矿山可视化管理系统分为三个模块，分别是资源总览、车辆分析和在线监控，各个模块的数据可以通过山海鲸内置数据源接口与矿山管理系统进行对接。数据源支持实时刷新，模块内容支持自定义修改和定制化开发。</div>
      // <div className={styles.strContent}>
      //   {
      //     strArr?.map((item,index) => {
      //       return <>
      //         <div className={styles.strBox} key={item.key}>
      //           <p> <img src={item.src} /></p>
      //           <p className={styles.boxTitle}>{item.name}</p>
      //           <p className={styles.boxContent}>{item.content}</p>
      //         </div>
      //       </>
      //     })
      //   }
      // </div>
      // </>
       })

       }
      </div>
    </>
  )
}
export default Structure

const strArr = [{
  key: 'str1',
  name: '资源总览',
  content: '矿山产业信息,生产情况',
  src: structure1
},
{
  key: 'str2',
  name: '车辆分析',
  content: '车辆分析,运输情况',
  src: structure2
}, {
  key: 'str3',
  name: '在线监控',
  content: '监控设备状态，视频监控',
  src: structure3
},
]
const mineArr=[
  {
    name:'智慧矿山',
    content:'整个智慧矿山可视化管理系统分为三个模块，分别是资源总览、车辆分析和在线监控，各个模块的数据可以通过山海鲸内置数据源接口与矿山管理系统进行对接。数据 源支持实时刷新，模块内容支持自定义修改和定制化开发。',
    arr:[
      {
        key: 'str1',
        name: '项目总览',
        content: '地理位置、交通优势、供应市场',
        src: structure1
      },
      {
        key: 'str2',
        name: '矿山开采',
        content: '地质岩性、车辆管理、开采模拟',
        src: structure2
      }, {
        key: 'str3',
        name: '砂石工厂',
        content: '数字孪生工',
        src: structure3
      },
    ]
  },
  {
    name:'乡村振兴',
    content:'整个乡村振兴可视化管理系统分为四个模块，分别是项目位置、乡村振兴、党建引领和网格管理，各个模块的数据可以通过内置数据源接口与矿山管理系统进行对接。数据源支持实时刷新，模块内容支持自定义修改和定制化开发。',
    arr:[
      {
        key: 'str1',
        name: '项目位置',
        content: '',
        src: structure1
      },
      {
        key: 'str2',
        name: ' 乡村振兴',
        content: '',
        src: structure2
      }, {
        key: 'str3',
        name: '党建引领 ',
        content: '',
        src: structure3
      },
      {
        key: 'str4',
        name: '网格管理 ',
        content: '',
        src: structure3
      },
    ]
  },
  {
    name:'智慧园区',
    content:'整个智慧园区系统分为四个模块，分别是园区概览，资源管理，设备运维和智慧安防，各个模块的数据可以通过内置数据源接口与园区设备进行对接，数据源支持实时刷新，模块内容支持自定义修改和定制化开发。',
    arr:[
      {
        key: 'str1',
        name: '园区概览',
        content: '',
        src: structure1
      },
      {
        key: 'str2',
        name: '资源管理',
        content: '',
        src: structure2
      }, {
        key: 'str3',
        name: '设备运维 ',
        content: '',
        src: structure3
      },
      {
        key: 'str4',
        name: '智慧安防 ',
        content: '',
        src: structure3
      },
    ]
  },
  {
    name:'智慧校园',
    content:'整个智慧校园系统分为四个模块，分别是学校概览、智慧教学、学生管理、智慧规划，各个模块的数据可以通过内置数据源接口与园区设备进行对接，数据源支持实时刷新，模块内容支持自定义修改和定制化开发。',
    arr:[
      {
        key: 'str1',
        name: '学校概览',
        content: '',
        src: structure1
      },
      {
        key: 'str2',
        name: ' 智慧教学',
        content: '',
        src: structure2
      }, {
        key: 'str3',
        name: '学生管理 ',
        content: '',
        src: structure3
      },
      {
        key: 'str4',
        name: '智慧规划 ',
        content: '',
        src: structure3
      },
    ]
  },
  {
    name:'智慧水电站',
    content:'整个智慧水电站系统分为四个模块，分别是工程位置、工程概况、数字建造、工程管理，各个模块的数据可以通过山海鲸内置数据源接口与园区设备进行对接，数据源支持实时刷新，模块内容支持自定义修改和定制化开发。',
    arr:[
      {
        key: 'str1',
        name: '工程位置',
        content: '',
        src: structure1
      },
      {
        key: 'str2',
        name: ' 工程概况',
        content: '',
        src: structure2
      }, {
        key: 'str3',
        name: '数字建造 ',
        content: '',
        src: structure3
      },
      {
        key: 'str4',
        name: '工程管理 ',
        content: '',
        src: structure3
      },
    ]
  },
]