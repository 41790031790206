import React from 'react'
import { aboutBg_3, } from '@/assets/image'
import styles from './index.module.less'

const Culture: React.FC<any> = () => {
  return (
    <>
      <div className={styles.culContainer}>
        <div className={styles.culTitle}>企业文化</div>
        <div className={styles.oneContent}>
          <div className={styles.leftBox_1}>使命</div>
          <div className={styles.rightBox_1}>我心飞翔 横戈跃马</div>
        </div>
        <div className={styles.twoContent}>
          <div className={styles.leftBox_2}>价值观</div>
          <div className={styles.rightBox_2}>
            <div><p>责任 </p><p>高效</p></div>
            <div className={styles.line}></div>
            <div><p>诚信 </p><p>协作</p></div>
            <div className={styles.line}></div>
            <div><p>创新 </p><p>共赢</p></div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Culture