import React, { useEffect, useState } from 'react'
import { map,location } from '@/assets/image'
// import {Map, Marker, NavigationControl, InfoWindow} from 'react-bmapgl';
import styles from './index.module.less'
import { start } from 'repl';


const Message: React.FC<any> = () => {
  // 1.在window上面引入BMap对象
  let BMap = (window as any).BMap;
  const [addressState,setaddressState]=useState({
    x:114.221525, 
    y:22.728827,
  })
  console.log(addressState);
  const OnChangeAddress=(j:any,w:any)=>{
    setaddressState({x:j,y:w})
}
  
  useEffect(() => {
    // 2.生成百度地图
    let map = new BMap.Map("container")
    // 3.聚焦到深圳
    var point = new (window as any).BMap.Point(addressState.x, addressState.y)
    map.centerAndZoom(point, 20)
    map.enableScrollWheelZoom();
    var opts = {
      position: new BMap.Point(addressState.x, addressState.y), // 指定文本标注所在的地理位置
      offset: new BMap.Size(-80, 0) // 设置文本偏移量
    };
    var label = new BMap.Label('飞戈数字', opts);
    // 自定义文本标注样式
    label.setStyle({
      color: 'black',
      borderRadius: '3px',
      borderColor: '#ccc',
      padding: '5px',
      fontSize: '14px',
      height: '20px',
      lineHeight: '20px',
      fontFamily: '微软雅黑'
    });

    map.addOverlay(label);

    // var map=new (window as any).BMap.Map('container')
    // var point=new (window as any).BMap.Point(114.221525, 22.728827)
    // map.centerAndZoom(point,18)
    // var map = new BMapGL.Map("container");    // 创建Map实例
    // map.centerAndZoom(new BMapGL.Point(114.221525, 22.728827), 13);  // 初始化地图,设置中心点坐标和地图级别
    //开启鼠标滚轮缩放 
    // map.enableScrollWheelZoom();  
  }, [addressState])

  return (
    <>
      <div className={styles.container}>
        <div className={styles.leftBox}>
          <div className={styles.conTitle}>
            <p>联系方式</p>
            <div className={styles.line}></div>
          </div>
          <div className={styles.box}>
            <div className={styles.address}><img src={location} /><div onClick={()=>OnChangeAddress(114.221525, 22.728827)}style={{color:(addressState.x===114.221525&& addressState.y===22.728827)?"#3564FE":''}}  className={styles.position}>深圳（业务部）</div></div>
            <p>服务热线:18073126662 </p>
            <p>商务合作:18073126662</p>
            <p>企业邮箱: cqlo0@163.com</p>
            <p>公司地址：深圳市龙岗区留学人员龙岗 </p>
            <p>创业园一园南区</p>
          </div>
          <div className={styles.box}>
            <div className={styles.address}><img src={location} /><div onClick={()=>OnChangeAddress(113.05003036722195,28.144799876677895)} style={{color:(addressState.x===113.05003036722195&& addressState.y===28.144799876677895)?"#3564FE":''}}  className={styles.position}>长沙（研发部）</div></div>
            <p>服务热线:18073126662 </p>
            <p>商务合作:18073126662</p>
            <p>企业邮箱: cqlo0@163.com</p>
            <p> 公司地址：长沙市雨花区香樟东路</p>
            <p>中南博远大厦3楼</p>
          </div>
          <div>

          </div>
        </div>
        <div className={styles.rightBox}>
          {/* <div><img src={map} width={800}/></div> */}
          <div id='container' style={{ width: 800, height: 700 }}>
          </div>
        </div>
      </div>
    </>
  )
}
export default Message
// export default MapApiLoaderHoc({ak:'HicDtgQSc0qfviGy1uESQj9iXNrNcNld'})(Message)