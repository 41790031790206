import React, { useState } from 'react'
import { Input, Button, } from 'antd';
import { UserOutlined, SafetyCertificateFilled, CloseOutlined } from '@ant-design/icons';
import { isValidNumber } from 'libphonenumber-js';
import axios from 'axios'
import styles from './index.module.less'

export const LoginBox = (props: any) => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    // 验证码发送状态
    const [code, setCode] = useState(false);
    const [code1, setCode1] = useState(false);
    const [isLoginSuccessful, setIsLoginSuccessful] = useState(false);
    const [videoState, setVideoState] = React.useState(false)
    const { codeState, getVideo, getCode } = props;
    const [state, setState] = useState(codeState)
    const [msgFlag ,setMsgFlag]=useState(false)

    const handlePhoneNumberChange = (event: any) => {
        const regex = /^1[3456789]\d{9}$/;
        const phone=event.target.value
        // setPhoneNumber(phone);
        // console.log(regex.test(phone));
        if(regex.test(phone)){
            console.log(regex.test(phone));
            setPhoneNumber(phone);
            setMsgFlag(false)
        }
        else{
            setPhoneNumber(phone);
            setMsgFlag(true) 
        }

        
    };

    const handleVerificationCodeChange = (event: any) => {
        setVerificationCode(event.target.value);
    };
    // 视频关闭
    const onClose = () => {
        setState(false)
        getCode(false)
    }
    console.log(phoneNumber, "phoneNumber");

    const handleSendVerificationCode = () => {
        // 验证码状态
      if(!msgFlag){
        setCode(true)
        axios.get(`https://trtc.iyunwu.cn/admin/app/web/getCode/${phoneNumber}`).
        then(function (response) {
            console.log(response);
        })
      }
       else if(msgFlag){ 
        setCode(false)
        setMsgFlag(true)
      }
        // 使用libphonenumber库验证手机号格式
        // const phoneNumberIsValid = isValidNumber(phoneNumber);
        // if (phoneNumberIsValid) {
        //   // 向后端发送请求发送验证码到用户手机
        //   try {
        //     await axios.post('/api/sendVerificationCode', { phoneNumber: phoneNumber });
        //     console.log("成功");
        //     // 发送成功的处理
        //   } catch (error) {
        //     // 发送失败的处理
        //     console.log("失败");    
        //   }
        // } else {
        //   // 手机号格式不正确的处理
        //   console.log("格式不正确");  
        // }


        // if (phoneNumber === "123456") {
        //     console.log("成功");
        //     setVerificationCode("123456")
        // } else {
        //     alert("输入失败，请重新输入")
        //     console.log("失败");
        // }
    };

    const handleLogin = () => {
        // 这里可以调用后端API验证手机号和验证码的正确性
        // 如果验证成功，设置isLoginSuccessful为true，表示登录成功
        // 如果验证失败，可以提示用户手机号或验证码错误
        axios.post('https://trtc.iyunwu.cn/admin/app/web/validateCode',{
             phoneNumber: phoneNumber ,code:verificationCode
        })
            .then(function (response) {
                // 处理成功情况
              if(response.data.code===200){
                setVideoState(true)
                setState(false)
                getCode(false)
                getVideo(true)
                console.log(response.data.msg);
                (response.data.msg);
              }else{
                setCode1(true)
                // alert(response.data.msg);
              }

            })
    };

    return (
        <>
            {
                state &&
                <div className={styles.loginContainer}>
                    <div className={styles.loginBox}>
                        <div className={styles.loginContent}>
                            <div className={styles.closeIcon} onClick={() => { onClose() }} style={{ color: '#fff' }}><CloseOutlined rev={undefined} /></div>
                            <div className={styles.title}>用户登录</div>
                            <div className={styles.phone}><Input  size="large" placeholder="请输入手机号" id="userphone" maxLength={20} value={phoneNumber} onChange={handlePhoneNumberChange} prefix={<UserOutlined rev={undefined} />} /> <Button onClick={handleSendVerificationCode}>{code?'已发送':'发送验证码'}</Button></div>
                            { (phoneNumber.length!==0)&&(msgFlag?<div style={{fontSize:'12px',position:'absolute',top:'160px',left:'80px',color:'red'}}>手机号格式有误，请重新输入</div>:<div style={{fontSize:'12px',position:'absolute',top:'160px',left:'80px',color:'green'}}>输入正确</div>)}
                            <div className={styles.loginCode}><Input size="large" placeholder="请输入验证码" value={verificationCode} onChange={handleVerificationCodeChange} prefix={<SafetyCertificateFilled rev={undefined} />} /> </div>
                           { code1&&<div style={{fontSize:'12px',position:'absolute',top:'238px',left:'80px',color:'red'}}>验证码不正确</div>}
                            <div>
                                <Button type="primary" shape="round" size='middle' onClick={handleLogin}  >
                                    立即提交
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
