import React, { useState } from "react";
import styles from './index.module.less'
import SuccessCaseItems from './items'
import { HoverMenu } from '@/components/hoverMenu'
import { successBg } from "@/assets/image";
import classNames from "classnames";
import Main from "../solution/wisdomHose/item/Main";


// 成功案例
const SuccessCase: React.FC<any> = () => {
    const [caseMenuState, setCaseMenuState] = useState<any>('SutraCase')

    const Component: any = SuccessCaseItems[caseMenuState]    // menu切换,改变模块

    const handleClick = (param: any) => {
        setCaseMenuState(param.key)
    }

    return <>

        <div className={styles.container}
        // style={{
        //     height: 952,
        //     width: '100%',
        //     background: `url(${successBg})`,
        //     backgroundSize: '100% 100%',
        //     backgroundRepeat: 'no-repeat'
        // }}
        >
            {/* <HoverMenu /> */}
            <img src={successBg} style={{ position: 'absolute', height: 925, width: '100%', zIndex: 0 }} />
            <div className={styles.title}>
                成功案例
            </div>
            <div className={styles.menuItems}>
                <Main/>

                {/* {
                    buttonList?.map((item: any) => {
                        return <>
                            <div onClick={() => handleClick(item)} className={classNames(
                                styles.itemBox,
                                caseMenuState === item.key && styles.itemBoxActive
                            )}>{item.name}</div>
                        </>
                    })
                } */}
            </div>
            {/* <Component /> */}
        </div>
    </>
}

const buttonList = [
    {
        key: 'SutraCase', name: '经典案例'
    }, {
        key: 'SmartMine', name: '智慧矿山'
    }, {
        key: 'EstateCIM', name: '地产CIM'
    }, {
        key: 'WisdomTravel', name: '智慧文旅'
    }, {
        key: 'WisdomWater', name: '智慧水利'
    }, {
        key: 'SmartHydropower', name: '智慧水电'
    }, {
        key: 'Revitalization', name: '乡村振兴'
    }, {
        key: 'Education', name: '教育医疗'
    },
]

export default SuccessCase