import React from 'react'
import { UserOutlined } from '@ant-design/icons';
import { Input, Button, } from 'antd';
import { DownloadOutlined, PhoneOutlined } from '@ant-design/icons';
// import type { SizeType } from 'antd/es/config-provider/SizeContext';
import axios from 'axios';
import styles from './index.module.less'

const Message: React.FC<any> = () => {
  // const [size, setSize] = React.useState<SizeType>('large');
  const [state,setState]=React.useState(true)
  const [name,setName]=React.useState('')
  const [phone,setPhone]=React.useState('')
  const [content,setContent]=React.useState('')

  const getName = (event:any) => {
      setName(event.target.value);
  }
  const getPhone = (event:any) => {
      setPhone(event.target.value);
  }

  const buttonSubmit = () => {
      // 如果验证失败，可以提示用户手机号或验证码错误
      axios.post('https://trtc.iyunwu.cn/admin/app/web/insentCustom', {
          name: name, phone: phone, content: ''
      }).
      then(function (response) {
          console.log(response);
      })
      setState(false)
  }
  return (
    <>
     {state&&
      <>
       <div className={styles.container} onMouseLeave={()=>{setState(false)}}>
        <p className={styles.title}>免费获取资料</p>
        <Input size="large" placeholder="姓名"  onChange={getName} prefix={<UserOutlined rev={undefined}/>} />
        <Input size="large" placeholder="电话"  onChange={getPhone} prefix={<PhoneOutlined rev={undefined}/>} />
        <Button type="primary" shape="round" size='middle' onClick={() => buttonSubmit()} >
          立即提交
        </Button>
        <p style={{ padding: '0 26px' }}>客服人员会与您联系免费提供资料</p>
      </div>
      </>
     }
    </>
  )
}
export default Message