import React from 'react'
import ConOnePage from './cononepage'
import Message from './message'
import LastBlock from "../lastBlock";
import styles from './index.module.less'

const Connection:React.FC<any>=()=> {
  return (
  <>
  <div className={styles.connectionBg}>
    {/* 首页 */}
    <ConOnePage/>
    {/* 联系方式 */}
    <Message/>
    {/* 底图 */}
    <LastBlock/>
  </div>
  </>
  )
}
export default Connection