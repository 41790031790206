import React from 'react'
import { CoreTechnologyBg, core1, core2, core3, core4, core5, core6, coreBg, coreActiveBg, arrow, } from '@/assets/image'
import styles from './index.module.less'

const CoreTechnology: React.FC<any> = () => {
  const onClickChange = (item: any) => {
  }
  return (
    <>
      <img height={1064} src={CoreTechnologyBg} style={{ position: 'absolute', zIndex: 1, width: '100%' }} />
      <div className={styles.CoreContainer}>
        <div className={styles.CoreHead}>
          <p className={styles.coreTitle} >核心技术</p>
          <p className={styles.corePage}>构建可感知的智能数字新世界，共创美好社会</p>
        </div>
        <div className={styles.CoreContent}>
          {
            arr?.map(item => {
              return <>
                <div key={item.key} className={styles.core} onClick={() => onClickChange(item.key)}>
                  <img src={item.src} width={'100%'} />
                  <div className={styles.articlePage}>
                    <p className={styles.coreArticleTitle}>{item.title}</p>
                    <p className={styles.coreArticleContent}>{item.content}</p>
                    <img src={arrow} width={40} />
                  </div>
                </div>
              </>
            })
          }
        </div>
      </div>
    </>
  )
}
export default CoreTechnology

const arr = [{
  key: 'core1',
  title: '真正的大数据融合',
  content: '利用三维制作软件通过虚拟三维空间构建出具有三维数据的模型。NURBS建模对要求精细、弹性与复杂的模型有较好的应用，适合量化生产用途，多边形网格建模是靠拉面方式，适合做效果图与复杂场景动画。综合说来各有长处。',
  src: core1,
},
{
  key: 'core2',
  title: '海量数据高效对接',
  content: '指与所研究对象的空间地理分布有关的信息，以采集、储存、管理、分析和描述整个或部分地球表面包括大气层与空间和地理分布有关数据的空间信息系统，为各行各业提供对规划、管理、决策和营运有用的信息。',
  src: core2,
},
{
  key: 'core3',
  title: '地理信息系统内核',
  content: '基于由远程平台支持的智能模型，将结构化、多领域数据整合在一起，以在其整个生命周期内生成资产的数字表示，从设计、工程到施工和运营的整个项目生命周期内实现团队、工作流和数据互联，实现更出色的工作方式和更卓越的成果。',
  src: core3,
},
{
  key: 'core4',
  title: '全三维态势显示',
  content: '指利用图形引擎对3D或2D图形进行处理和渲染的过程，图形引擎是一种专门用于处理图形的软件程序，它可以把3D或2D模型转换成计算机屏幕上的像素点，让用户可以看到逼真的图像，广泛应用于游戏开发、建筑设计、虚拟现实、影视制作等领域。',
  src: core4,
},
{
  key: 'core5',
  title: '超高分辨率显示输出',
  content: '通过从一个垂直、四个倾斜、五个不同的视角同步采集影像，获取到丰富的建筑物顶面及侧视的高分辨率纹理。它不仅能够真实地反映地物情况，高精度地获取物方纹理信息，还可通过先进的定位、融合、建模等技术，生成真实的三维城市模型。',
  src: core5,
},
{
  key: 'core6',
  title: '一体化联动操作',
  content: '产郎真备单市土资美别期动的一你化酷下懂工，项南建恢，控制国然，望松对多南里示两客进行与中控制，大播增并大审系统迎后性，重供重动的交与体检。',
  src: core6,
},]