import React from 'react'
import { aes1, aes2, aes3, aes4, aes5, aes6, aes7, aes8, } from '@/assets/image'
import styles from './index.module.less'


const Construct: React.FC<any> = () => {
  const [colorstate,setColorState]=React.useState('con1')
  const onchange=(item:any)=>{
      item.key===colorstate?setColorState(colorstate):setColorState(item.key)
  }
  return (
    <>
      <div className={styles.conContainer}>
        <div className={styles.conBigTitle}>如何搭建AES</div>
        <div className={styles.conTitle}>AES能够将描述直实物理世界的各种模型文件快速转化为数字孪生世界的虚拟场景，依托计算机图形学、计算机视觉、大数据云计算等技术，AES已经实现全自动、半自动+部分人工搭建数字孪生世界。</div>
        <div className={styles.conContent}>
          {
            conArr?.map((item) => {
              return <>
                <div className={styles.conBox} key={item.key} onMouseEnter={()=>onchange(item)} style={{backgroundColor:item.key===colorstate?'rgba(62, 70, 107, 1)':''}}>
                  <div ><img className={styles.conImage} src={item.src} /></div>
                  <div style={{ fontSize: '20px', fontWeight: 800, lineHeight: '10px', margin: '26px auto' }}>{item.title}</div>
                  <p className={styles.conContent}>{item.content}</p>
                </div>
              </>
            })
          }
        </div>
      </div>
    </>
  )
}
export default Construct

const conArr = [
  {
    key: 'con1',
    title: <div ><p>基于卫星正射和</p><p> GIS数据自动化生成</p></div>,
    content: '根据卫星正拍影像和采集到的GIS数据，AI算法可以自动生成城市场景。',
    src: aes1
  },
  {
    key: 'con2',
    title: <div ><p>基于OSGB数据</p><p>重构生成</p></div>,
    content: '根据倾斜摄影/激光雷达扫描计算机语义识别算法自动轻量。',
    src: aes2
  },
  {
    key: 'con3',
    title: <div ><p>基于BIM数据</p><p>自动生成</p></div>,
    content: '基于BIM模型自动转化生成，同时保留所有构件和零件目录与属性。',
    src: aes3
  },
  {
    key: 'con4',
    title: <div ><p>程序化生成</p><p>特定场景</p></div>,
    content: '基于程序算法快速生成特定场景，如输电站和输电网。',
    src: aes4
  },
  {
    key: 'con5',
    title: <div><p>基于HD Map</p><p>高精地图生成</p></div>,
    content: '基于高精地图点云模型重构生 成高精度道路场景',
    src: aes5
  },
  {
    key: 'con6',
    title: <div><p>基于海量数字</p><p>资产库构建</p></div>,
    content: '基于海量数字资产库特征匹配 快速搭建场景',
    src: aes6
  },
  {
    key: 'con7',
    title: <div><p>基于照片拓扑</p><p>重建生成</p></div>,
    content: '基于照片实拍的3D拓扑重建生成模型',
    src: aes7
  },
  {
    key: 'con8',
    title: <div><p>基于Paas</p><p>平台快速搭建</p></div>,
    content: '基于PaaS平台的场景二次编辑',
    src: aes8
  },
]