import React from 'react'
import styles from './index.module.less'
import Ecology from './items/Ecology'
import CooperationGoal from './items/CooperationGoal'
import CooperationType from './items/CooperationType'
import CoperationEquity from './items/CoperationEquity'
import CooparetionFlow from './items/CooparetionFlow'
import CooperationInput from './items/CooperationInput'
import LastBlock from '../lastBlock'

const Cooperation: React.FC<any> = () => {
    return <>
        <div className={styles.cooperation}>
            <Ecology/>
            {/* 合作目标 */}
            <CooperationGoal/>
            {/* 合作类型 */}
            <CooperationType/>
            {/* 合作权益 */}
            <CoperationEquity/>
            {/* 合作流程 */}
            <CooparetionFlow/>
            {/* 免费获取方案与报价 */}
            <CooperationInput/>
            {/* foot */}
            <LastBlock />
        </div>
    </>
}

export default Cooperation