import React from 'react';
import styles from './index.module.less';
import { icon4, icon5, icon6, icon7, icon8, icon9 } from '@/assets/cooperation/image'

// 合作流程
const CooparetionFlow: React.FC<any> = () => {
    return <>
        <div className={styles.cooparetionFlow}>
            <div className={styles.title}>合作流程</div>
            <div className={styles.body}>
                {
                    list.map((item: any, index: number) => {
                        return <>
                            <div className={styles.items}>
                                <div className={styles.content}>
                                    <img src={item.logo} style={{ width: 68 }} />
                                    <div> {item.text}</div>
                                </div>
                                {
                                    (index !== (list.length - 1)) && < img src={icon9} style={{ width: 20 }} />
                                }
                            </div>
                        </>
                    })
                }
            </div>
        </div>
    </>
}

const list = [
    {
        key: 'flow1',
        text: '1.留言联系我们',
        logo: icon4,
    }, {
        key: 'flow2',
        text: '2.实名认证登记',
        logo: icon5,
    }, {
        key: 'flow3',
        text: '3.选择伙伴类型',
        logo: icon6,
    }, {
        key: 'flow4',
        text: '4.颁发合作授权',
        logo: icon7,
    }, {
        key: 'flow5',
        text: '5.成为合作伙伴',
        logo: icon8,
    },
]

export default CooparetionFlow
