import React from 'react'
import { modelBg, service_Bg } from '@/assets/image'
import styles from './index.module.less'

const AesModel: React.FC<any> = () => {
  const [state, setState] = React.useState(modelBg)
  const [colorState,setColorState]=React.useState("L1")
  const onChangePng = (item: any) => {
    setColorState(item.key)
    setState(item.src)
  }
  return (
    <>
      <div className={styles.aesContainer}>
        <div className={styles.aesBigTitle}>AES场景建模</div>
        <div className={styles.aesTitle}>
          <p> 全要素场景是飞戈数字的核心价值，其包含来自现实世界的城市、建筑、工厂、环境、设施、产线等场景，同时兼具视觉真实、地理信息、物理模拟及自由交互等要素。</p>
          <p> 全要素场景(AIl Element Scene，简称AES) AES共分为5个等级，即L1~L5，随等级的提升，场景也越来越趋近于真实世界!</p>
        </div>
        <div className={styles.aesContent}>
          <div className={styles.leftBox} >
            <div style={{width:'988px',height:'500px',overflow:'hidden'}}><img src={state} /></div>
            <div className={styles.imgMask}></div>
              <div className={styles.box}>
                  {
                    aesArr.map(item=>{
                      return<>
                     {item.key===colorState&&
                    <>
                    <div className={styles.boxTitle}>{item.key} - {item.name}</div>
                    <div className={styles.boxContent}>
                      <p>主要数据：<b>{item.mainData}</b></p>
                      <p>推荐视距：<b>{item.stadia}</b></p>
                      <p>坐标精度：<b>{item.coordinate}</b></p>
                      <p>结构精度：<b>{item.structure}</b></p>
                      <p>纹理精度：<b>{item.texture}</b></p>
                      <p>应用场景：<b>{item.Apply}</b></p>
                    </div>
                    </>
                     }
                      </>
                    })
                  }
              </div>          
          </div>
          <div className={styles.rightBox}>
            {
              aesArr?.map(item => {
                return <>
                  <div key={item.key} className={colorState===item.key?styles.aesBoxActive:styles.aesBox} onClick={() => onChangePng(item)}>
                    <span style={{ fontStyle: 'italic', fontSize: '36px', fontWeight: 800, marginRight: '16px' }}>{item.key}</span> <span>{item.name}</span>
                  </div>
                </>
              })
            }
          </div>
        </div>
      </div>
    </>
  )
}
export default AesModel

const aesArr = [
    {
        key: 'L1',
        name: '城市级',
        src: modelBg,
        mainData:"CIS",
        stadia:"500米-20公里",
        coordinate:"5米内(上帝视角)",
        structure:"无",
        texture:"无",
        Apply:"省市全域，上万平方公里的大型场景",
      },
      {
        key: 'L2',
        name: '区域级',
        src: service_Bg,
        mainData:"GIS、遥感数据",
        stadia:"500米-20公里(鸟瞰)",
        coordinate:"5米内",
        structure:"无",
        texture:"卫星通感影像",
        Apply:"市全域，上千平方公里的大型场层",
      },
      {
        key: 'L3',
        name: '场景级',
        src: modelBg,
        mainData:"OSGB、CAD、CIM",
        stadia:"500米-20公里",
        coordinate:"1米内",
        structure:"主要组件",
        texture:"高洁纹理贴图",
        Apply:"区全域，上百平方公里的中型场景",
      },
      {
        key: 'L4',
        name: '构件级',
        src: modelBg,
        mainData:"CAD、BIM、扫描模型",
        stadia:"5米-2000米(漫游)",
        coordinate:"厘米级",
        structure:"全部组件",
        texture:"超清材质贴图",
        Apply:"街道区域，一平方公里内的小型场景",
      },
      {
        key: 'L5',
        name: '零件级',
        src: modelBg,
        mainData:"CAD、BIM、扫描、3D打印数据",
        stadia:"0.2米-100米(特号)",
        coordinate:"厘米级",
        structure:"细节零件",
        texture:"物理仿真级",
        Apply:"实时数据动态驱动孪生设备模型构件",
  },
]