import React from 'react'
import { metBg } from '@/assets/image'
import styles from './index.module.less'

const MetOnePage: React.FC<any> = () => {
  return (
    <>
      <div className={styles.onePageContainer} 
      style={{
        background: `url(${metBg})`,
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat'
      }}>
        <div className={styles.titleBox}>
          <div className={styles.bigTitle} >
           <p> 虚拟空间 </p>
           <p> 元宇宙搭建</p>
          </div>
          <div className={styles.context} >
            <p>虚拟人+多种场景+多种社交</p>
            <div className={styles.line}></div>
            <p>每家企业都有属于自己的元宇宙</p>
          </div>
        </div>
      </div >
    </>

  )
}
export default MetOnePage