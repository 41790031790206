import React from 'react'
import styles from './index.module.less'
import { Carousel } from 'antd';

const Card = (props: any) => {
  const { list } = props
  return (
    <>
      <div className={styles.container}>
        {/* <Carousel autoplay> */}
          {
            list.map((item1: any) => {
              return <>
                <div className={styles.content}>
                  {
                    item1.map((item: any) => {
                      return <>
                        <div >
                          {
                            item.video && <>
                              <video src={item.video} className={styles.item} />
                            </>
                          }
                          {
                            item.img && <>
                              <div className={styles.imageContainer}>
                                <img src={item.img} className={styles.item} />
                                <div className={styles.title}>{item.name}</div>
                                <div className={styles.overlay}>
                                  <i className={styles.icon} />
                                </div>
                              </div>
                            </>
                          }
                        </div>
                      </>
                    })
                  }
                </div>
              </>
            })
          }
        {/* </Carousel > */}
      </div>
    </>
  )
}
export default Card