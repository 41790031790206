import React from 'react'
import { more_1, more_2, more_3, arrow } from '@/assets/image'
import { Carousel } from 'antd';
import styles from './index.module.less'
import { title } from 'process';

const KnowMore: React.FC<any> = () => {
  return (
    <>
      <div className={styles.KnowContainer}>
        <div className={styles.KnowHead}>
          <p className={styles.KnowTitle} >了解更多</p>
        </div>
        <div style={{ width: '100%', height: '500px' }}>
          <Carousel autoplay>
            {
              arr?.map((item1) => {
                return <>
                  <div className={styles.KnowContent}>
                    {
                      item1?.map((item) => {
                        return <>
                          <div className={styles.KnowBox} style={{ marginRight: '34px' }} >
                            <img width={'378px'} height={'248px'} src={item.src} />
                            <p className={styles.knowTitle}>{item.title}</p>
                            <p className={styles.content}>{item.content}</p>
                            <div className={styles.line}></div>
                            <div><p className={styles.knowTime}>{item.time}</p><img width={21} style={{ position: 'relative', left: '180px', top: '-30px' }} src={arrow} /></div>
                          </div >
                        </>
                      })
                    }
                  </div >
                </>
              })
            }
          </Carousel>
        </div>
      </div >
    </>
  )
}
export default KnowMore
const arr = [
  [
    {
      key: 'core1',
      title: '最新资讯',
      content: '利用三维制作软件通过虚拟三维空间构建出具有三维数据的模型...',
      time: '2023.7.10',
      src: more_1,
    },
    {
      key: 'core2',
      title: '最新资讯',
      content: '利用三维制作软件通过虚拟三维空间构建出具有三维数据的模型...',
      time: '2023.6.12',
      src: more_2,
    },
    {
      key: 'core3',
      title: '最新资讯',
      content: '利用三维制作软件通过虚拟三维空间构建出具有三维数据的模型...',
      time: '2023.5.5',
      src: more_3,
    },
  ],
  [
    {
      key: 'core4',
      title: '最新资讯',
      content: '利用三维制作软件通过虚拟三维空间构建出具有三维数据的模型...',
      time: '2023.7.10',
      src: more_1,
    },
    {
      key: 'core5',
      title: '最新资讯',
      content: '利用三维制作软件通过虚拟三维空间构建出具有三维数据的模型...',
      time: '2023.6.12',
      src: more_2,
    },
    {
      key: 'core6',
      title: '最新资讯',
      content: '利用三维制作软件通过虚拟三维空间构建出具有三维数据的模型...',
      time: '2023.5.5',
      src: more_3,
    },
  ], [
    {
      key: 'core4',
      title: '最新资讯',
      content: '利用三维制作软件通过虚拟三维空间构建出具有三维数据的模型...',
      time: '2023.7.10',
      src: more_1,
    },
    {
      key: 'core5',
      title: '最新资讯',
      content: '利用三维制作软件通过虚拟三维空间构建出具有三维数据的模型...',
      time: '2023.6.12',
      src: more_2,
    },
    {
      key: 'core6',
      title: '最新资讯',
      content: '利用三维制作软件通过虚拟三维空间构建出具有三维数据的模型...',
      time: '2023.5.5',
      src: more_3,
    },
  ], [
    {
      key: 'core4',
      title: '最新资讯',
      content: '利用三维制作软件通过虚拟三维空间构建出具有三维数据的模型...',
      time: '2023.7.10',
      src: more_1,
    },
    {
      key: 'core5',
      title: '最新资讯',
      content: '利用三维制作软件通过虚拟三维空间构建出具有三维数据的模型...',
      time: '2023.6.12',
      src: more_2,
    },
    {
      key: 'core6',
      title: '最新资讯',
      content: '利用三维制作软件通过虚拟三维空间构建出具有三维数据的模型...',
      time: '2023.5.5',
      src: more_3,
    },
  ]
]
