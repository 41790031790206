import React from 'react'
import styles from './index.module.less'
import { icon1, icon2, icon3, icon4 } from '@/assets/workOut/image'

// 客户价值

const FifthBlock: React.FC<any> = () => {
    return <>
        <div className={styles.fifthBlock}>
            <div className={styles.titleBox}>
                客户价值
            </div>
            <div className={styles.contentBox}>
                通过智联集成平台与各主流通讯协议、接口兼容对接，全面连接园区一切可连接设备与系统，提供跨域的消息传递能力，实现数据共享与互动，为园区提供可视、可管、可控的基础设施运维管理能力。
            </div>
            <div className={styles.body}>
                {
                    list?.map((item: any) => {
                        return <>
                            <div className={styles.itemBox}>
                                <img src={item.logo} />
                                <div className={styles.title}>
                                    {item.title}
                                </div>
                                <div className={styles.text}>
                                    {item.text}
                                </div>
                            </div>
                        </>
                    })
                }
            </div>
        </div>
    </>
}

const list = [
    {
        title: '打造数字化亮点 ',
        logo: icon1,
        text: '打造以项目特色为主题”专属名片"以数字化、科技感的展现手段表达项目特色及优势,让参访者更为深刻的理解项目的亮点与底蕴。',
    }, {
        title: '提升看房体验',
        logo: icon2,
        text: '在项目招商前置的阶段,通过1.1高仿真的呈现方式,轻应用、里体验,使其覆盖面广、接受程度高、传播便捷。',
    }, {
        title: '降低招商成本',
        logo: icon3,
        text: '减少对实体样板房、图文物料、效果图、视频、建模等方面的大颤投入。场景可根据项目方需求进行便捷的升级、修改，节省大量的费用与时间',
    }, {
        title: '提高客户转化',
        logo: icon4,
        text: '通过数字孪生多维度的交互可以比单一传统物料更能全面、快速了解项目详情情况，顾客所见即所得，营销说辞更具备说服力，转化效果。更好',
    },
]
export default FifthBlock