import React from 'react'
import styles from './index.module.less'

const TitlePage: React.FC<any> = () => {
    return <>
        <div className={styles.titleContainer}>
            <div className={styles.title}>飞戈数字 </div>
            <div className={styles.subTitle}>元宇宙城市底座服务商</div>
        </div>
    </>
}

export default TitlePage