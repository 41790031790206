import React from "react";
import styles from './index.module.less'
import SutraCase from "./sutraCase";
import SmartMine from "./smartMine";
import EstateCIM from "./estateCIM";
import WisdomTravel from "./wisdomTravel";
import WisdomWater from "./wisdomWater";
import SmartHydropower from "./smartHydropower";
import Revitalization from "./revitalization";
import Education from "./education";

// 成功案例
const SuccessCaseMain: React.FC<any> = () => {

    return <>
    </>
}

const SuccessCaseItems = SuccessCaseMain as unknown as any;

// 导出个模块
SuccessCaseItems.SutraCase = SutraCase
SuccessCaseItems.SmartMine = SmartMine
SuccessCaseItems.EstateCIM = EstateCIM
SuccessCaseItems.WisdomTravel = WisdomTravel
SuccessCaseItems.WisdomWater = WisdomWater
SuccessCaseItems.SmartHydropower = SmartHydropower
SuccessCaseItems.Revitalization = Revitalization
SuccessCaseItems.Education = Education

export default SuccessCaseItems