import React, { useState, useEffect } from 'react'
import { img2, img3, img4, img5 } from '@/assets/workOut/image'
// import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import classNames from 'classnames'
import SelfCarousel from '@/components/SelefCarousel';
import _ from 'lodash';

import { corefunction1, corefunction2, corefunction3, corefunction4, corefunction5, corefunction6, corefunction7, corefunction8, corefunction9, corefunction10, } from '@/assets/image'
import styles from './index.module.less'

const CoreFunction: React.FC<any> = () => {
    const [hoverShow, setHoverShow] = useState<any>()
    const [imgData, setImgData] = useState<any>()

    useEffect(() => {
        setImgData(_.chunk(imgList, 4))
    }, [])
    return (
        <>
            <div className={styles.coreContainer}>
                <div className={styles.coretitle}>
                    <p className={styles.corebigtitle}>核心功能</p>
                    <p className={styles.corelittletitle}>满足全方位活动需求，多样化互动娱乐功能，强化沉浸式社交体验</p>
                </div>
                <div className={styles.coreTop}>
                    {
                        topArr?.map(item => {
                            return <>
                                <div className={styles.coreTopBox}>
                                    <div className={styles.coreMask}></div>
                                    <img src={item.src} width={394}/>
                                    <p>{item.name}</p>
                                </div>
                            </>
                        })
                    }
                </div>
                <div className={styles.coreFoot}>
                <div className={styles.body}>
                    {imgData?.map((list: any) => {
                        return <>
                            <div className={styles.centerBox}>
                                {
                                    list?.map((item: any) => {
                                        return <>
                                            <div className={classNames(
                                                styles.item,)}
                                                // onMouseOver={() => setHoverShow(item.key)}
                                                // onMouseOut={() => setHoverShow(null)}
                                            >
                                                <img style={{width:'294px'}} src={item.img} />
                                                <div className={styles.mask}></div>

                                                {/* {hoverShow === item.key && */}
                                                    <div className={styles.hoverContent}>
                                                        <div className={styles.title}>
                                                            {item.title}
                                                        </div>
                                                        <div className={styles.content}>
                                                            {item.content}
                                                        </div>
                                                    </div>
                                                {/* } */}
                                            </div>
                                           
                                        </>
                                    })
                                }
                            </div>

                        </>
                    })}

            </div>
                </div>
            </div>
        </>
    )
}
export default CoreFunction

const topArr = [
    {
        key: 'core1',
        name: '烟花秀',
        src: corefunction1
    },
    {
        key: 'core1',
        name: '儿童活动厅',
        src: corefunction2
    },
    {
        key: 'core1',
        name: '会场',
        src: corefunction3
    },
    {
        key: 'core1',
        name: '企业会议室',
        src: corefunction4
    },
    {
        key: 'core1',
        name: '颁奖典礼',
        src: corefunction5
    },
    {
        key: 'core1',
        name: '签约仪式',
        src: corefunction6
    },
]
const imgList = [
    {
        key: 'Third1',
        title: '在线展厅 ',
        content:
         <div>
        <p>艺术展</p>
        <p>虚拟展会</p>
        <p>品牌云展厅</p>
        <p>文博展馆</p>
        <p>元宇宙商城</p>
        </div>
       ,
        img: corefunction7,
    }, {
        key: 'Third2',
        title: '会议活动 ',
        content:    <div>
        <p> 发布会</p>
        <p>学术会议</p>
        <p>行业峰会</p>
        <p>企业年会</p>
        <p>晚会庆典</p>
        </div>,
        img: corefunction8,
    }, {
        key: 'Third3',
        title: '社交娱乐 ',
        content:    <div>
        <p> 剧本杀</p>
        <p>在线桌游</p>
        <p>派对聚会</p>
        <p>虚拟演唱会馆</p>
        <p>元宇宙蹦迪</p>
        </div>,
        img: corefunction9,
    }, {
        key: 'Third4',
        title: '更多场景',
        content:    <div>
        <p>拍卖会</p>
        <p>虚拟直播</p>
        <p>元宇宙婚礼</p>
        <p>元宇宙办公</p>
        <p>招聘宣讲</p>
        </div>,
        img: corefunction10,
    },
      
]
