import React, { useState } from 'react'
import {villageIndex} from "@/assets/image";
import styles from '../index.module.less'
import Introduction from '../introduction'
import Structure from '../structure'
import SystemDisplay from '../systemDisplay'
import LastBlock from '../../lastBlock'
const FigureVillage: React.FC<any> = () => {
    const [ChildrenState,setChildrenState]=useState("乡村振兴")
  return (
    <>
      <div className={styles.container}>
        {/* 首页 */}
        <div className={styles.onePage} style={{backgroundImage:`url(${villageIndex})`,backgroundRepeat:'no-repeat',backgroundSize:'100% 100%'}}>
          <p className={styles.onePageTitle}>乡村振兴三维可视化系统</p>
        </div>
        {/* 系统介绍 */}
        <Introduction ChildrenState={ChildrenState}/>
        {/* 系统结构 */}
        <Structure ChildrenState={ChildrenState}/>
        {/* 系统展示 */}
        <SystemDisplay ChildrenState={ChildrenState}/>
        {/* 底图 */}
        <LastBlock />
      </div>
    </>
  )
}

export default FigureVillage