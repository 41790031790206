import React from 'react'
import { projectCaseBg } from '@/assets/image'
import styles from './index.module.less'

const OnePage:React.FC<any>=()=> {
  return (
    <>
        <div className={styles.onePageContainer} style={{
        background: `url(${projectCaseBg})`,
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat'
      }}>
        <div className={styles.titleBox}>
          <div className={styles.bigTitle} >多行业项目案例一览</div>
          <div className={styles.context} >
            <p>主要覆盖的行业应用场景包括智慧城市、园区、交通、校园、化工、数字工厂、</p>
            <p>景区、桥梁、仓储、电厂、电站、环保、农业、乡村、矿山等40+行业。</p>
          </div>
        </div>
      </div >
    </>
  )
}
export default OnePage