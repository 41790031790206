import React, { useState } from "react";
import styles from './index.module.less'
import { serviceBg, serviceR1, serviceR2, serviceR3, serviceR4 } from "@/assets/image";
import classnames from 'classnames'



// 右侧六个块按钮
const rightList = [
    {
        key: 'serviceR1', name: '场景试综合解决方案', icon: serviceR1,
        firstText: 'SCENARIO TRIAL COMPREHENSIVE SOLUTION',
        firstTitle: '场景试综合解决方案',
        secondTitle: '覆盖全行业一体化解决方案',
        secondText: '包括智慧城市、园区、校园、房地产、路桥、水利水电、文博文旅、乡村振兴等',
    }, {
        key: 'serviceR2', name: '专业自助定制化', icon: serviceR2,
        firstText: 'PROFESSIONAL SELF CUSTOMIZATION',
        firstTitle: '专业自助定制化',
        secondTitle: '满足不同领域多样化业务个性需求',
        secondText: '提供完整的全链路数字孪生研发，实现从模型创建、场景搭建、数据处理、效果定义的全流程专业服务',
    }, {
        key: 'serviceR3', name: '全要素可视化', icon: serviceR3,
        firstText: 'TOTAL ELEMENT VISUALIZATION',
        firstTitle: '全要素可视化',
        secondTitle: '设计、模型、交互、数据等流程可视化',
        secondText: '集成多学科、多物理量、多尺度、多概率的仿真过程，通过5G物联网技术在虚拟空间中完成映射，反映相对应业务的全生命周期过程',
    }, {
        key: 'serviceR4', name: '跨平台跨硬件访问', icon: serviceR4,
        firstText: 'CROSSPLATFORM,CROSSHARDWARE ACCESS',
        firstTitle: '跨平台跨硬件访问',
        secondTitle: '与合作伙伴产品完美融合',
        secondText: '提供客户端、手机端、网页等访问形式，可在手机、PAD、PC、大屏、一体机、VR眼镜等设备交互，并与各类软件进行集成，便于品牌推广营销',
    }, {
        key: 'serviceR5', name: '业务动态化模式', icon: serviceR1,
        firstText: 'BUSINESS DYNAMIC MODE',
        firstTitle: '业务动态化模式',
        secondTitle: '智能动态运营服务中心',
        secondText: '结合客户业务场景，融合各业务系统的指标数据，借助物联网、大数据、人工智能等新型数字化技术，构建运营中心',
    }, {
        key: 'serviceR6', name: '一站式贴心服务', icon: serviceR4,
        firstText: 'ONESTOP SERVICE',
        firstTitle: '一站式贴心服务',
        secondTitle: '数字经济时代服务成就价值',
        secondText: '提供一站式服务支持，7*24小时在线，售前技术评估，售中技术交流，售后无间断服务，全周期服务体系',
    },
]

// 服务优势
const ServiceAdvantage: React.FC<any> = () => {
    const [buttonState, setButtonState] = useState<any>()
    const [rightItemState, setRightItemState] = useState<any>()
    let leftContent = rightList.filter((item) => item.key === rightItemState)
    console.log('leftContent', leftContent);

    // TODO 了解详情,咨询按钮,点击事件
    const handleClick = (props: any) => {
        setButtonState(props.key)
    }

    // TODO 右侧items点击事件
    const rightItemClick = (props: any) => {
        setRightItemState(props.key)
    }

    return <>
        <div className={styles.container}
            style={{
                background: `url(${serviceBg})`,
                backgroundSize: '100% 100%',
                backgroundRepeat: 'no-repeat'
            }}
        >
            <div className={styles.titleBox}>
                <div className={styles.title}>服务优势</div>
                <div className={styles.context}>一站式综合解决方案 + 个性化专业定制服务</div>
            </div>
            <div className={styles.mainBox}>
                <div className={styles.left}>
                    {!rightItemState &&
                        <><div className={styles.first}>
                            <div className={styles.firstText}>SCENARIO TRIAL COMPREHENSIVE SOLUTION</div>
                            <div className={styles.firstTitle}>
                                场景试综合解决方案
                            </div>
                        </div>
                            <div className={styles.second}>
                                <div className={styles.secondTitle}>覆盖全行业一体化解决方案</div>
                                <div className={styles.secondText}>包括智慧城市、园区、校园、房地产、路桥、水利水电、文博文旅、乡村振兴等</div>
                                <div className={styles.secondBottonBox}>
                                    {
                                        buttonList?.map((item: any) => {
                                            return <>
                                                <div onClick={() => handleClick(item)}
                                                    className={classnames(
                                                        styles.secondButton,
                                                        buttonState === item.key && styles.secondButtonActive
                                                    )}>
                                                    {item.name}
                                                </div>
                                            </>
                                        })
                                    }
                                </div>
                            </div>
                        </>
                        // })

                    }
                    {rightItemState &&
                        <><div className={styles.first}>
                            <div className={styles.firstText}>{leftContent[0].firstText}</div>
                            <div className={styles.firstTitle}>
                                {leftContent[0].firstTitle}
                            </div>
                        </div>
                            <div className={styles.second}>
                                <div className={styles.secondTitle}>  {leftContent[0].secondTitle}</div>
                                <div className={styles.secondText}> {leftContent[0].secondText}</div>
                                <div className={styles.secondBottonBox}>
                                    {
                                        buttonList?.map((item: any) => {
                                            return <>
                                                <div onClick={() => handleClick(item)}
                                                    className={classnames(
                                                        styles.secondButton,
                                                        buttonState === item.key && styles.secondButtonActive
                                                    )}>
                                                    {item.name}
                                                </div>
                                            </>
                                        })
                                    }
                                </div>
                            </div>
                        </>
                        // })

                    }
                </div>
                <div className={styles.right}>
                    {
                        rightList?.map((item: any) => {
                            return <>
                                <div onClick={() => rightItemClick(item)}
                                    className={classnames(
                                        styles.rightItem,
                                        rightItemState === item.key && styles.rightItemActive
                                    )}>
                                    <img src={item.icon} style={{ width: 50, height: 50 }} />
                                    <div>{item.name}</div>
                                </div>
                            </>
                        })
                    }

                </div>
            </div>
        </div >
    </>
}

// 左侧按钮
const buttonList = [
    {
        key: 'details', name: '了解详情'
    },
    {
        key: 'consult', name: '咨询'
    },
]
// 左边内容
// const serviceList = [
//     {
//         key: 'server1',
//         name: '场景试综合解决方案',
//         firstText: 'SCENARIO TRIAL COMPREHENSIVE SOLUTION',
//         firstTitle: '场景试综合解决方案',
//         secondTitle: '覆盖全行业一体化解决方案',
//         secondText: '包括智慧城市、园区、校园、房地产、路桥、水利水电、文博文旅、乡村振兴等',
//     },
//     {
//         key: 'server1',
//         name: '专业自助定制化',
//         firstText: 'SCENARIO TRIAL COMPREHENSIVE SOLUTION',
//         firstTitle: '专业自助定制化合解决方案',
//         secondTitle: '覆盖全行业一体化解决方案',
//         secondText: '包括智慧城市、园区、校园、房地产、路桥、水利水电、文博文旅、乡村振兴等',
//     },
//     {
//         key: 'server1',
//         name: '全要素可视化',
//         firstText: 'SCENARIO TRIAL COMPREHENSIVE SOLUTION',
//         firstTitle: '全要素可视化解决方案',
//         secondTitle: '覆盖全行业一体化解决方案',
//         secondText: '包括智慧城市、园区、校园、房地产、路桥、水利水电、文博文旅、乡村振兴等',
//     },

// ]


export default ServiceAdvantage