import React from 'react'
import styles from './index.module.less'

const TelePhone: React.FC<any> = () => {
  return (
    <>
      <div className={styles.container}>
        <p className={styles.title}>拨打电话</p>
        <p>150-0214-8755</p>
      </div>
    </>
  )
}
export default TelePhone 