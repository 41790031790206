import React from "react";
import styles from './index.module.less'
import { foot1, foot2, titleIcon } from "@/assets/image";


const LastBlock: React.FC<any> = () => {
    return <>
        <div style={{ backgroundColor: 'rgba(17, 17, 17, 1)' }}>
            <div className={styles.container}>
                <div className={styles.titleBox}>
                    <img src={titleIcon} width={'183px'} className={styles.img} />
                    {/* <div className={styles.title}>飞戈数字</div> */}
                </div>
                <div className={styles.secondBox}>
                    <div className={styles.leftBox}>
                        <div className={styles.telephone}>
                            <div className={styles.item}>
                                <div className={styles.label}>服务热线</div>
                                <div className={styles.tel}>18073126662</div>
                            </div>
                            <div className={styles.item}>
                                <div className={styles.label}>商务合作</div>
                                <div className={styles.tel}>18073126662</div>
                            </div>
                            <div className={styles.item} style={{ border: 'none' }}>
                                <div className={styles.label}>企业邮箱</div>
                                <div className={styles.tel}>cqlo0@163.com</div>
                            </div>
                        </div>
                        <div className={styles.addressBox}>
                            <div className={styles.title}>公司地址：</div>
                            <div className={styles.details}>
                                湖南省长沙市雨花区香樟东路30号中南博远大厦3楼<br />
                                广东省深圳市龙岗区留学人员龙岗创业园一园南区
                            </div>
                        </div>
                    </div>
                    <div className={styles.rightBox}>
                        {/* <div className={styles.rightItem}>
                            <div className={styles.title}>微信公众号</div>
                            <img src={foot1} className={styles.img} />
                        </div> */}
                        <div className={styles.rightItem}>
                            <div className={styles.title}>合作咨询</div>
                            <img src={foot2} className={styles.img} />
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.bottomBox}>
                <div className={styles.line} />
                <div className={styles.text}>
                    本网站为网络信息服务平台，如涉及侵权或其他问题，请联系我们处理
                </div>
                <div className={styles.text}>
                    Copyright@2016-2023 湖南飞戈数字科技有限公司 feigesz.com All Rights Reserved ©<a href="https://beian.miit.gov.cn" style={{ color: '#358ef7' }} target={'_blank'}>湘ICP备19015077号</a>
                </div>
                <div className={styles.text}>
                    友情链接: 中国电能  | 青海大学
                </div>
            </div></div>
    </>
}
export default LastBlock