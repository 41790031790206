import React from "react";
import styles from './index.module.less';
import classNames from 'classnames';
import { useFormik } from 'formik';
import { SelfInput } from "@/components/SelfInput";
import axios from "axios";

const CooperationInput: React.FC<any> = () => {
    const [name,setName]=React.useState('')
    const [phone,setPhone]=React.useState('')
    const [content,setContent]=React.useState('')

    const getName = (event:any) => {
        setName(event.target.value);
    }
    const getPhone = (event:any) => {
        setPhone(event.target.value);
    }
    const getContent=(event:any) => {
        setContent(event.target.value);
    }
    const buttonSubmit = () => {
        // 如果验证失败，可以提示用户手机号或验证码错误
        axios.post('https://trtc.iyunwu.cn/admin/app/web/insentCustom', {
            name: name, phone: phone, content: content
        }).
        then(function (response) {
            console.log(response);
        })
    }

    return <>
        <div className={styles.cooperationInput}>
            <div >
                <div className={styles.title}>飞戈数字孪生，助力政企数字化转型</div>
                <div className={styles.sub}>数字经济时代 服务成就价值</div>
            </div>
            <div className={styles.body}>
                <div className={styles.inputBox}>
                    <SelfInput
                        name='name'
                        placeholder='请输入您的姓名'
                        message='名字不能为空'
                        onChange={getName}
                    />
                    <SelfInput
                        name="phone"
                        placeholder="请输入您的电话"
                        message='电话不能为空'
                        onChange={getPhone}

                    />
                </div>
                <textarea placeholder="请描述您的需求"
                    className={styles.textarea} 
                    onChange={getContent}
                    />
    
            </div>
            <button type="submit" className={styles.button}   onClick={() => buttonSubmit()}>
                免费获取方案及报价
                
            </button>
        </div>
    </>
}

export default CooperationInput