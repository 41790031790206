import React, { useState, useEffect } from 'react'
import styles from './index.module.less'
import { img1, img2, img3, img4, img5 } from '@/assets/workOut/image'
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import classNames from 'classnames'
import SelfCarousel from '@/components/SelefCarousel';
import _ from 'lodash';

// 模型等级

const ThirdBlock: React.FC<any> = () => {
    const [hoverShow, setHoverShow] = useState<any>()
    const [imgData, setImgData] = useState<any>()

    useEffect(() => {
        setImgData(_.chunk(imgList, 5))
    }, [])

    return <>
        <div className={styles.thirdBlock}>
            <div className={styles.title}>模型等级</div>
            <div className={styles.body}>
                {/* <SelfCarousel dots={false}> */}
                {imgData?.map((list: any) => {
                    return <>
                        <div className={styles.centerBox}>
                            {
                                list?.map((item: any) => {
                                    return <>
                                        <div className={classNames(
                                            styles.item,)}
                                            onMouseOver={() => setHoverShow(item.key)}
                                            onMouseOut={() => setHoverShow(null)}
                                        >
                                            <img style={{ width: '230px' }} src={item.img} />

                                            <div className={styles.imgMask}></div>
                                            {hoverShow === item.key &&
                                                <div className={styles.hoverContent}>
                                                    <div className={styles.title}>
                                                        {item.title}
                                                    </div>
                                                    <div className={styles.content}>
                                                        {item.content}
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </>
                                })
                            }
                        </div>

                    </>
                })}

                {/* </SelfCarousel> */}
            </div>
        </div>
    </>
}

const imgList = [
    {
        key: 'Third1',
        title: '单体级 ',
        content: '基于GIS数据的超低面数模型用于超大规模地区级场景',
        img: img1,
    }, {
        key: 'Third2',
        title: '半体单级 ',
        content: '基于GIS数据与倾斜摄影的低模用于大规模城市级场景',
        img: img2,
    }, {
        key: 'Third3',
        title: '配模级 ',
        content: '基于GIS数据与倾斜摄影的中模用于米级精度中等规模区域级场景',
        img: img3,
    }, {
        key: 'Third4',
        title: '精模级',
        content: '基于倾斜摄影与BIM数据的高模用于厘米级精度小规模片区级场景',
        img: img4,
    }, {
        key: 'Third5',
        title: '主模级 ',
        content: '基于BIM数据的百万级面数高模用于一比一还原全要素构件级场景',
        img: img5,
    }

]

export default ThirdBlock