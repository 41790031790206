import React from "react";

/**
 * 自宝义HOOK，利用localStorage存储，实现组件共享数据
 * @param key 
 * @param value 
 * @returns 
 */
export const useStore = (key: string, value?: any) => {
    const [state, setState] = React.useState<any>(parseValue(key) || value);

    //自宝义事件，设置state值
    const onStorageChange = (e: any) => {
        if (e.key !== key) {
            return;
        }
        setState(e.newValue);
    }
    window.addEventListener('storageChange', onStorageChange, false);

    const setStore = (newValue: any) => {
        window.localStorage.setItem(key, setValue(newValue));
        let event: any = new Event('storageChange');
        event.newValue = newValue;
        event.key = key;
        window.dispatchEvent(event);
    }

    React.useEffect(() => {
        window.localStorage.getItem(key) == null && value != null && setStore(value);

        return () => {
            window.removeEventListener('storageChange', onStorageChange);
        }
    }, [])

    return [state, setStore]
}

/**
 * 取值，转成json格式
 * @param key 
 * @returns 
 */
const parseValue = (key: string) => {
    let value: any = window.localStorage.getItem(key);
    if (value != null) {
        value = JSON.parse(value);
        return value.value;
    }
    return null;
}

/**
 * json格式字符串存储
 * @param newValue 
 * @returns
 */
const setValue = (newValue: any) => {
    return JSON.stringify({ value: newValue, type: typeof newValue });
}