import React from 'react'
import styles from './index.module.less'
import Introduction from './introduction'
import Structure from './structure'
import SystemDisplay from './systemDisplay'
import LastBlock from '../lastBlock'
const Solution: React.FC<any> = () => {
  return (
    <>
      <div className={styles.container}>
        {/* 首页 */}
        <div className={styles.onePage}>
          <p className={styles.onePageTitle}>智慧园区三维可视化系统</p>
        </div>
        {/* 系统介绍 */}
        <Introduction />
        {/* 系统结构 */}
        <Structure />
        {/* 系统展示 */}
        <SystemDisplay />
        {/* 底图 */}
        <LastBlock />
      </div>
    </>
  )
}

export default Solution